<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">ご意見・ご要望送信完了</li>
    </ol>
  </nav>
  <div class="container">
    <div class="top--section">
      <h2>ご意見・ご要望送信完了</h2>
      <br />
      ご意見・ご要望を送信しました。<br />
      <br />
      <!-- <form method="GET" action="/"> -->
        <div class="text-center">
          <router-link to="/" class="btn btn-primary">トップページへ戻る</router-link>
        </div>
      <!-- </form> -->
    </div>
   
  </div>
</template>
