<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">error</li>
    </ol>
  </nav>
  <div class="container">
    <div class="top--section">
      <h1>{{ title }}</h1>
      <p>
				{{ content }}<br />
			</p>
			<router-link :to="{ path: '/top'}" class="btn btn-primary">トップページへ戻る</router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useLoadingStore } from '@/stores/loading'
  import { ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  const title = ref('')
  const content = ref('')
  // title.value = useRoute().query.title ? useRoute().query.title.toString() : 'エラー'
  title.value = (useRoute().query.title ?? 'エラー').toString()
  content.value = (useRoute().query.content ?? 'エラーが起こりました。トップページに戻ってください。').toString()
  useLoadingStore().stop()
</script>
