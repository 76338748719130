<template>
<nav aria-label="breadcrumb" class="breadcrumb-area">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
    <li class="breadcrumb-item active" aria-current="page">パスワード再設定</li>
  </ol>
</nav>
  <div class="container">
  		<h1>メール送信完了</h1>
		<div class="top--section">
  			<p>
				パスワード再設定メールをお送りしました。<br>
				メールに記載しているパスワード再設定URLの有効期限は12時間です。<br>
				有効期限内に再設定ができなかった場合は、再度お手続きをお願いします。<br>
				<br>
				しばらく経ってもメールが届かない場合は、以下の可能性が考えられますのでご確認の上、再度お手続きをお願いします。<br>
				・メールアドレスの入力間違い<br>
				・迷惑メールに分類されている<br>
				・「@tokyoymca.org」からのメールが受信できない設定になっている<br>
			</p>
			<router-link to="/" class="btn btn-primary">トップページへ戻る</router-link>
		</div>
	</div>
</template>
