import { InValidServiceException, TokenMismatchException, UnAuthorizedException } from '@/config/exception'
import router from "@/router"
import { useMemberStore } from "@/stores/member"

export function handleErrors (error: Error) {
  console.error(error)
  if (error.name === 'InValidServiceException') {
    return
  }
  if (error.name === 'UnAuthorizedException') {
    if (router.currentRoute.value.name !== 'LoginIndex') 
      // UnAuthorizedException.plsLogin()
    useMemberStore().logout()
    // router.push('/')
    router.push({ name: 'Error', query: { title: 'セッション無効', content: 'セッションが無効です。再度ログインしてください。'} })
    return
  }
  if (error.name === 'TokenMismatchException') {
    // TokenMismatchException.plsLogin()
    return
  }
  if (error.name === 'InternalServerException') {
    return
  }
  if (error.name === 'UnprocessableException') {
    return
  }
}
