<template v-if="useEntryStore().parent">
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">メンバー登録 -お子様プロフィール入力-</li>
    </ol>
  </nav>
  <div class="container">
    <div class="top--section">
      <h1>メンバー登録 -お子様プロフィール入力-</h1>
      <child-component v-for="(child, index) in children" :key="child.count" ref="childRef" :child="child"
        :index="index" :deleteChild="deleteChild" />
      <div class="btn-area mb-5">
        <button class="btn btn-primary" type="button" @click="addChild">子ども追加</button>
      </div>
      <div class="text-danger" style="align-items: center;display: flex;flex-direction: column;" v-if="duplicateMessage">{{ duplicateMessage }}</div>
      <div class="btn-area mb-5">
        <button class="btn btn-apply" type="button" @click="handleBack()">戻る</button>
        <button class="btn btn-apply" type="button" @click="handleToConfirm()">確認へ</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Ref, computed, reactive, ref } from 'vue'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { API } from '../../api'
import { useLoadingStore } from '@/stores/loading'
import ChildComponent from './child.vue'
import { TMember } from '@/types/member'
import { useMemberStore } from '@/stores/member'
import { useEntryStore } from '@/stores/entry'
import jsonpAdapter from 'axios-jsonp'
import { handleErrors } from '@/config/handleErrors'
import { getAge } from '@/js/util'

const router = useRouter()
const thisYear = new Date().getFullYear()
const memberStore = useMemberStore()
const parent = ref<TMember>(TMember.createEmptyMember())
// useLoadingStore().start()

configure({
validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

const schema = yup.object({
// mail_address: yup.string().required('ユーザーIDは必須項目です').email('メールアドレスの形式で入力してください'),
password: yup.string().required('パスワードは必須項目です').matches(/^[A-Za-z0-9]+$/, '半角英数字で入力してください').min(8, '8文字以上で入力してください'),
lastName: yup.string().required('氏名は必須項目です').max(16, '氏名は16文字以内で入力してください'),
firstName: yup.string().required('氏名は必須項目です').max(16, '氏名は16文字以内で入力してください'),
lastNameKana: yup.string().required('氏名(フリガナ)は必須項目です').max(16, '氏名(フリガナ)は16文字以内で入力してください').matches(/^[\u30A1-\u30F6ー]+$/, '全角カタカナのみを入力してください'),
firstNameKana: yup.string().required('氏名(フリガナ)は必須項目です').max(16, '氏名(フリガナ)は16文字以内で入力してください').matches(/^[\u30A1-\u30F6ー]+$/, '全角カタカナのみを入力してください'),
year: yup.string().required('年は必須項目です'),
month: yup.string().required('月は必須項目です'),
day: yup.string().required('日は必須項目です'),
gender: yup.number().required('性別は必須項目です'),
phoneNumber: yup.string().required('電話番号は必須項目です').max(13, '電話番号は13桁以内で入力してください').matches(/^\d{10,13}$/, { message: '全て数字で13桁以内で入力してください' }),
postalCode: yup.string().required('郵便番号は必須項目です').max(7, '郵便番号は7桁以内で入力してください').matches(/^\d{7}$/, { message: '半角数字7桁で入力してください', excludeEmptyString: true }).nullable(),
prefecture: yup.string().required('都道府県は必須項目です'),
city: yup.string().required('市区町村は必須項目です').max(20, '市区町村は20文字以内で入力してください'),
streetAddress: yup.string().required('番地は必須項目です').max(20, '番地は20文字以内で入力してください'),
buildingName: yup.string().max(100, '建物名は100文字以内で入力してください').nullable(),
birthday: yup.date().test('birthday', '保護者年齢に該当しません', function(value) {
  if (year.value && month.value && day.value) {
      const birthday = new Date(Number(year.value), Number(month.value) - 1, Number(day.value)); // 誕生日を Date オブジェクトに変換
      return getAge(birthday) >= 18
    }else {
      return true
    }
  })
})

const { validate, errors, values } = useForm({ validationSchema: schema });
// const mailAddress = atob(router.currentRoute.value.query.address!.toString())
const mailAddress = ref('')
const { value: year } = useField<string>('year')
const { value: month } = useField<string>('month')
const { value: day } = useField<string>('day')
const { value: gender } = useField<number>('gender')
const duplicateMessage = ref<string>('')

const getLastDay = (year: number, month: number): number => {
return new Date(year, month, 0).getDate()
}

const lastDay = computed(() => {
if (year.value && month.value) {
  return getLastDay(Number(year.value), Number(month.value))
} else {
  return 31
}
})

const getAddress = async () => {
await API.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${parent.value.postalCode}`, { adapter: jsonpAdapter }).then(r => {
  if (r.data.status === 200 && r.data.results && r.data.results.length > 0) {
    const result = r.data.results[0]
    parent.value.prefecture = result.address1
    parent.value.city = result.address2
    parent.value.streetAddress = result.address3
  } else {
    parent.value.prefecture = ''
    parent.value.city = ''
    parent.value.streetAddress = ''
  }
})
}

const children = ref<TMember[]>([])
const childRef = ref<typeof ChildComponent[]>([])
const childErrors = ref<any[]>([])

const childMethod = async () => {
if (childRef.value) {
  await Promise.all(childRef.value.map(async child => {
    await child.childValidate()
  }))
}
}
const count = ref(0)
const addChild = async () => {
await childMethod()
const valid = children.value.find(c => {
  return c.valid && c.valid.hasOwnProperty('valid') && c.valid.valid === false
})
if (valid) return
const newChild = TMember.createEmptyMember(++count.value)
children.value.push(
  newChild
)
}
const deleteChild = (index: number) => {
children.value.splice(index, 1)
count.value--
}

// const handleUpdate = handleSubmit(async (values) => {
// const handleStore = async () => {
//   try {
//     useLoadingStore().start()
//     childErrors.value = []
//     await childMethod()
//     // const valid = await validate()
//     // if (!valid.valid) {
//     //   useLoadingStore().stop()
//     //   return
//     // }
//     const childValidations = await Promise.all(children.value.map(async (c) => {
//       if (!await c.valid.valid) {
//         childErrors.value.push(c.valid.errors)
//         return false;
//       }
//       return true;
//     }))
//     if (!childValidations.every(validation => validation)) {
//       useLoadingStore().stop()
//       return
//     }
//     TMember.storeEntry({ parent: parent.value, children: children.value }).then(response => {
//       useLoadingStore().stop()
//       if (response.data.status == "success") {
//         router.push('/entry/complete')
//       }
//       else {
//         duplicateMessage.value = response.data.message
//       }
//     })
//   } catch (e: any) {
//     useLoadingStore().stop()
//     handleErrors(e)
//   }
// }

const handleToConfirm = async () => {
try {
  useLoadingStore().start()
  childErrors.value = []
  await childMethod()
  const childValidations = await Promise.all(children.value.map(async (c) => {
    if (!await c.valid.valid) {
      childErrors.value.push(c.valid.errors)
      return false;
    }
    return true;
  }))
  if (!childValidations.every(validation => validation)) {
    useLoadingStore().stop()
    return
  }
  useEntryStore().setChildren(children.value)
  router.push({ name: 'EntryProfileConfirm'})
} catch (e: any) {
  useLoadingStore().stop()
  handleErrors(e)
}
}

onMounted(async () => {
try {
  if (!useEntryStore().parent) {
    router.push({ name: 'Error', query: { title: '保護者情報エラー', content: '保護者情報を入力し直してください' } })
  } else {
    if (useEntryStore().parent) parent.value = useEntryStore().parent
    if (useEntryStore().children) {
      children.value = useEntryStore().children
      count.value = children.value[children.value.length - 1].count
    }
  }
  useLoadingStore().stop()
} catch (e: any) {
  handleErrors(e)
  useLoadingStore().stop()
}
if (children.value && children.value.length < 1) addChild()
})

const handleBack = () => {
try {
  useEntryStore().setParent(parent.value)
  useEntryStore().setChildren(children.value)
  router.back()
} catch (e: any) {
  useLoadingStore().stop()
  handleErrors(e)
}
}
</script>