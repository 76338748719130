<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">パスワード再設定</li>
    </ol>
  </nav>
  <div class="container">
  		<h1>パスワード再設定</h1>
		<div class="top--section">
			<div class="mb-3 row">
				<label for="inputEmail" class="col-sm-2 col-form-label">メールアドレス</label>
				<div class="col-sm-10">
					<Field type="text" name="mailAddress" class="form-control" id="mailAddress"/>
          <div class="text-danger form-error">{{ errors.mailAddress }}</div>
				</div>
			</div>
			<div class="mb-3 row">
				<label for="inputEmailConfirm" class="col-sm-2 col-form-label">メールアドレス(確認)</label>
				<div class="col-sm-10">
					<Field type="text" name="mailAddressConfirm" class="form-control" id="mailAddressConfirm"/>
          <div class="text-danger form-error">{{ errors.mailAddressConfirm }}</div>
				</div>
			</div>
		    <button class="btn btn-primary w-100 py-2" type="submit" @click="handlePassword" >送信</button><br />
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { API } from '../../api'
import { handleErrors } from '@/config/handleErrors';
import { useLoadingStore } from '@/stores/loading'

  const router = useRouter()

  const schema = yup.object({
  mailAddress: yup.string().required('メールアドレスは必須項目です').email('メールアドレスの形式で入力してください'),
  mailAddressConfirm: yup.string().required('メールアドレス(確認)は必須項目です').email('メールアドレスの形式で入力してください').test('match', 'メールアドレスと一致しません', function(value) {
      return value === this.parent.mailAddress;
    })
  });

  configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
  });

	const { validate, errors, values } = useForm({ validationSchema: schema });
	const { value: mailAddress } = useField<string>('mailAddress')
	const { value: mailAddressConfirm } = useField<string>('mailAddressdConfirm')

  const handlePassword = async() => {
    useLoadingStore().start()
    try {
      const valid = await validate()
      if (!valid.valid) return
      API.post('/api/password/index', { mail_address: mailAddress.value})
        .then(res => {
          useLoadingStore().stop()
          if(res.data.status === 'success') {
            console.debug('送信成功')
            router.push('/password/mail')
          } else {
            console.debug('送信失敗')
          }
        })
    } catch(e: any) {
      useLoadingStore().stop()
      handleErrors(e)
    }
  }
// $(function() {
//   $('#btn_form_submit').prop('disabled', true);

//   $("input[type='checkbox']").on('change', function () {
//         if ($(".check:checked").length > 1) {
//           $("#btn_form_submit").prop("disabled", false);
//         } else {
//           $("#btn_form_submit").prop("disabled", true);
//         }
//   });
// });
</script>

