<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">メンバー登録 -確認-</li>
    </ol>
  </nav>
  <div class="container">
    <h1>メンバー登録 -確認-</h1>
    <div class="top--section">
      <div class="event-info tab-content">
        <div class="requirements-contents tab-pane active guideline-item">
          <h4 class="fs-5 mb-2" v-if="children.length > 0">保護者</h4>
          <!-- <hr class="dot-hr mt-0"> -->
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">メールアドレス</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.mailAddress }}
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">氏名</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.lastName }} &nbsp; {{ parent.firstName }}
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">氏名(フリガナ)</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.lastNameKana }} &nbsp; {{ parent.firstNameKana }}
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">生年月日</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.year }}年 {{ parent.month }}月 {{ parent.day }}日
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">性別</label>
            <p class="ps-2 col-sm-8 col-form-label" v-if="parent.gender === 1">男性</p>
            <p class="ps-2 col-sm-8 col-form-label" v-else-if="parent.gender === 2">女性</p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">電話番号</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.phoneNumber }}
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">郵便番号</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.postalCode }}
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">都道府県</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.prefecture }}
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">市区町村</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.city }}
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">番地</label>
            <p class="ps-2 col-sm-8 col-form-label">
              {{ parent.streetAddress }}
            </p>
          </div>
          <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">建物名・部屋番号</label>
            <p class="ps-2 col-sm-8 col-form-label" v-if="parent.buildingName">
              {{ parent.buildingName }}
            </p>
          </div>
          <template v-for="(child, index) in children">
            <h4 class="fs-5 mt-4 mb-2">子ども{{ index + 1 }}</h4>
            <div class="mb-3 mx-3 row profile-confirm-underline">
            <label class="col-sm-4 col-form-label">氏名</label>
              <p class="ps-2 col-sm-8 col-form-label">
                {{ child.lastName }} &nbsp; {{ child.firstName }}
              </p>
            </div>
            <div class="mb-3 mx-3 row profile-confirm-underline">
              <label class="col-sm-4 col-form-label">氏名(フリガナ)</label>
              <p class="ps-2 col-sm-8 col-form-label">
                {{ child.lastNameKana }} &nbsp; {{ child.firstNameKana }}
              </p>
            </div>
            <div class="mb-3 mx-3 row profile-confirm-underline">
              <label class="col-sm-4 col-form-label">生年月日</label>
              <p class="ps-2 col-sm-8 col-form-label">
                {{ child.year }}年 {{ child.month }}月 {{ child.day }}日
              </p>
            </div>
            <div class="mb-3 mx-3 row profile-confirm-underline">
              <label class="col-sm-4 col-form-label">性別</label>
              <p class="ps-2 col-sm-8 col-form-label" v-if="child.gender === 1">男性</p>
              <p class="ps-2 col-sm-8 col-form-label" v-else-if="child.gender === 2">女性</p>
            </div>
          </template>
        </div>
      </div>
      <div class="text-danger" style="align-items: center;display: flex;flex-direction: column;"
          v-if="duplicateMessage">{{ duplicateMessage }}</div>
      <div class="btn-area mb-5">
        <button class="btn btn-apply" type="button" @click="handleBack()">戻る</button>
        <button class="btn btn-apply" type="button" @click="handleStore()">登録</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Ref, computed, reactive, ref } from 'vue'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { API } from '../../api'
import { useLoadingStore } from '@/stores/loading'
import ChildComponent from './child.vue'
import { TMember } from '@/types/member'
import { useMemberStore } from '@/stores/member'
import { useEntryStore } from '@/stores/entry'
import jsonpAdapter from 'axios-jsonp'
import { handleErrors } from '@/config/handleErrors'
import { getAge } from '@/js/util'

const router = useRouter()
const thisYear = new Date().getFullYear()
const memberStore = useMemberStore()
const parent = ref<TMember>(TMember.createEmptyMember())
useLoadingStore().start()

// const mailAddress = atob(router.currentRoute.value.query.address!.toString())
const mailAddress = ref('')
const { value: year } = useField<string>('year')
const { value: month } = useField<string>('month')
const { value: day } = useField<string>('day')
const { value: gender } = useField<number>('gender')
const duplicateMessage = ref<string>('')

const children = ref<TMember[]>([])
const childRef = ref<typeof ChildComponent[]>([])
const childErrors = ref<any[]>([])

// const handleUpdate = handleSubmit(async (values) => {
const handleStore = async () => {
  try {
    useLoadingStore().start()
    TMember.storeEntry({ parent: parent.value, children: children.value }).then(response => {
      useLoadingStore().stop()
      if (response.data.status == "success") {
        router.push('/entry/complete')
      }
      else {
        duplicateMessage.value = response.data.message
      }
    })
    useEntryStore().clearEntry()
  } catch (e: any) {
    useLoadingStore().stop()
    handleErrors(e)
  }
}
// })

onMounted(async () => {
  try {
    parent.value = useEntryStore().getParent()
    children.value = useEntryStore().getChildren()
    useLoadingStore().stop()
    if (!parent.value.mailAddress) {
      router.push({name: 'Top'})
    }
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
})

const handleBack = () => {
  try {
    useEntryStore().setParent(parent.value)
    useEntryStore().setChildren(children.value)
    router.back()
  } catch (e: any) {
    useLoadingStore().stop()
    handleErrors(e)
  }
}
</script>