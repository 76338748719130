import type { LocationQueryValue } from 'vue-router'

export const queryToString = (
  value: LocationQueryValue | LocationQueryValue[]
): string | undefined => {
  if (Array.isArray(value)) {
    return value[0] ?? undefined
  }
  return value ?? undefined
}

export const queryToNumber = (
  value: LocationQueryValue | LocationQueryValue[]
): number | undefined => {
  const stringValue = queryToString(value)
  if (stringValue !== null && !isNaN(Number(stringValue))) {
    return Number(stringValue)
  }
  return undefined
}

export const queryToNumbersArray = (
  values: LocationQueryValue | LocationQueryValue[]
): number[] => {
  // LocationQueryValueが配列でない場合、配列に変換して処理します
  const valueArray = Array.isArray(values) ? values : [values]
  // 配列の各要素を数値に変換します
  const numbers = valueArray
    .map(value => queryToString(value))
    .filter(stringValue => stringValue !== null && !isNaN(Number(stringValue)))
    .map(stringValue => Number(stringValue))

  return numbers
}
