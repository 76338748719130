<template>
  <input type="text" class="form-control" v-model="inputText" />
</template>

<script setup lang="ts">
  import { TChoice, TAnswer } from '@/types/event'
  import { ref, watch } from 'vue'

  const props = defineProps<{
    memberId: number
  }>()

  const options = defineModel<TChoice[]>('options')
  const choices = defineModel<TChoice[]>('choices')
  const answers = defineModel<TAnswer[]>('answers')
  const surveyId = defineModel<number>('surveyId')
  const inputText = ref('')
  watch(() => inputText.value, (newValue) => {
    inputText.value = newValue
    if (answers.value !== undefined) {
      const existingIndex = answers.value.findIndex(answer => answer.surveyId === surveyId.value)
      if (existingIndex !== -1) {
        // choices.value.splice(existingIndex, 1, newValue)
        answers.value.splice(existingIndex, 1, TAnswer.fromData(0, surveyId.value!, props.memberId, null, inputText.value))
      } else {
        // choices.value.push(newValue)
        answers.value.push(TAnswer.fromData(0, surveyId.value!, props.memberId, null, inputText.value))
      }
    }
  })
</script>