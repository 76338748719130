import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useLoadingStore = defineStore('loadingState', () => {
  
  const isLoading = ref(false)
  const fullPage = ref(true)
  
  function start() {
    isLoading.value = true
  }

  function stop() {
    isLoading.value = false
  }

  return {
    isLoading,
    fullPage,
    start,
    stop
  }
})
