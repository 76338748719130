<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">パスワード再設定</li>
    </ol>
  </nav>
  <div class="container">
    <h1>パスワード再設定</h1>
    <div class="top--section">
      <p>新しいパスワードを入力してください。</p>
      <div class="mb-3 row">
        <label for="inputPassword" class="col-sm-2 col-form-label">パスワード</label>
        <div class="col-sm-10">
          <Field type="password" name="password" class="form-control" id="inputPassword" />
          <div class="text-danger form-error">{{ errors.password }}</div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="inputPasswordConfirm" class="col-sm-2 col-form-label">パスワード(確認)</label>
        <div class="col-sm-10">
          <Field type="password" name="passwordConfirm" class="form-control" id="inputPasswordConfirm" />
          <div class="text-danger form-error">{{ errors.passwordConfirm }}</div>
        </div>
      </div>
      <button class="btn btn-primary w-100 py-2" type="button" @click="changePassword">登録</button><br />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { API } from '../../api'
import { handleErrors } from '@/config/handleErrors'

const router = useRouter()

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});

const schema = yup.object({
  password: yup.string().required('パスワードは必須項目です').min(8, 'パスワードは8文字以上で入力してください'),
  passwordConfirm: yup.string().required('パスワード(確認)は必須項目です').min(8, 'パスワード(確認)は8文字以上で入力してください').test('match', 'パスワードと一致しません', function (value) {
    return value === this.parent.password;
  }),
});

const { validate, errors, values } = useForm({ validationSchema: schema });
const mailAddress = ref('')
const { value: password } = useField<string>('password')
const { value: passwordConfirm } = useField<string>('passwordConfirm')

onMounted(async () => {
  try {
    if (!useRoute().query.t || useRoute().query.t === undefined) router.push({ name: 'NotFound' })
    await API.post('/api/password/certification', { token: useRoute().query.t?.toString() }).then(response => {
      if (response.data.status == 'success') {
        switch (response.data.message) {
          case 'valid':
            mailAddress.value = response.data.mail_address
            break
          case 'expired':
            router.push({ name: 'Expired' })
            break
          default:
            router.push({ name: 'Error', query: { title: '有効なURLではありません', content: '有効なURLではありません' } })
        }
      }
    })
  } catch (e: any) {
    handleErrors(e)
  }
})

const changePassword = async () => {
  const valid = await validate()
  if (!valid.valid) return
  try {
    API.post('/api/password/change', { password: password.value, mailAddress: mailAddress.value }).then(response => {
      if (response.data.status == "success") {
        router.push('/password/complete');
      }
      else {
      }
    })
  } catch(e: any) {
    handleErrors(e)
  }
}

</script>