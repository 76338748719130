<template>
  <h2>お子様情報の登録</h2>
  <div class="mb-3 mt-4">
    <div class="col-12">
      <h4>子ども{{ props.index + 1 }}</h4>
    </div>
    <div class="row">
      <label :for="`inputSei-child${index}`" class="col-sm-3 col-form-label">氏名</label>
      <div class="col-sm-3">
        <Field type="text" name="lastName" placeholder="姓" class="form-control" :id="`inputSei-child${index}`" maxlength="16" v-if="child" v-model="child.lastName"/>
        <div class="col-12 text-danger form-error"  v-if="child">{{ errors.lastName }}</div>
      </div>
      <div class="col-sm-3">
        <Field type="text" name="firstName" placeholder="名" class="form-control" :id="`inputMei-child${index}`" maxlength="16" v-if="child" v-model="child.firstName"/>
        <div class="col-12 text-danger form-error"  v-if="child">{{ errors.firstName }}</div>
      </div>
    </div>
  </div>
  <div class="mb-3 row">
    <label :for="`inputSeiKana-child${index}`" class="col-sm-3 col-form-label">氏名(フリガナ)</label>
    <div class="col-sm-3">
      <Field type="text" name="lastNameKana" placeholder="セイ" class="form-control" :id="`inputSeiKana-child${index}`" maxlength="16" v-if="child" v-model="child.lastNameKana"/>
      <div class="col-12 text-danger form-error"  v-if="child">{{ errors.lastNameKana }}</div>
    </div>
    <div class="col-sm-3">
      <Field type="text" name="firstNameKana" placeholder="メイ" class="form-control" id="inputMeiKana-child" maxlength="16" v-if="child" v-model="child.firstNameKana"/>
      <div class="col-12 text-danger form-error"  v-if="child">{{ errors.firstNameKana }}</div>
    </div>
  </div>
  <div class="mb-3 row">
    <label :for="`inputBirthday-child${index}`" class="col-sm-3 col-form-label">生年月日</label>
    <div class="col-sm-9 d-flex" v-if="child">
      <div class="col-sm-3">
        <div class="input-group">
          <Field as="select" name="year" :id="`inputBirthday-child${index}`" class="form-control form-select"  v-model="child.year">
            <option value=""></option>
            <option v-for="y in 20" :key="y" :value="(thisYear + 1) - y">{{ (thisYear + 1) - y }}</option>
          </Field>
          <div class="input-group-prepend">
            <div class="input-group-text">年</div>
          </div>
          <div class="col-12 text-danger form-error" v-if="child">{{ errors.year }}</div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group">
          <Field as="select" name="month" class="form-control form-select" v-model="child.month">
            <option value=""></option>
            <option v-for="m in 12" :value="m">{{ m }}</option>
          </Field>
          <div class="input-group-prepend">
            <div class="input-group-text">月</div>
          </div>
          <div class="col-12 text-danger form-error" v-if="child">{{ errors.month }}</div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group">
          <Field as="select" name="day" class="form-control form-select" v-model="child.day">
            <option value=""></option>
            <option v-for="n in lastDay" :value="n">{{ n }}</option>
          </Field>
          <div class="input-group-prepend">
            <div class="input-group-text">日</div>
          </div>
          <div class="col-12 text-danger form-error" v-if="child">{{ errors.day }}</div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="inputSex-child" class="col-sm-3 col-form-label">性別</label>
      <div class="col-sm-8 mt-2" v-if="child">
        <div class="form-check form-check-inline">
          <!-- <Field class="form-check-input" as="input" type="radio" :name="`gender_${index}`" :id="`child-gender-man${index}`" :value="1" @change="changeGender(1)" v-bind:checked="true"/> -->
          <input class="form-check-input" type="radio" :name="`gender${index}`" :id="`child-gender-man${index}`" @change="changeGender(1)"  :value="1" :checked="child.gender === 1"/>
          <label class="form-check-label" :for="`child-gender-man${index}`">男性</label>
        </div>
        <div class="form-check form-check-inline">
          <!-- <Field class="form-check-input" as="input" type="radio" :name="`gender_${index}`" :id="`child-gender-woman${index}`" :value="2" @change="changeGender(2)"/> -->
          <input class="form-check-input" type="radio" :name="`gender${index}`" :id="`child-gender-woman${index}`" @change="changeGender(2)" :value="2" :checked="child.gender === 2"/>
          <label class="form-check-label" :for="`child-gender-woman${index}`">女性</label>
        </div>
        <div class="col-12 text-danger form-error">{{ errors[`gender${index}`] }}</div>
      </div>
    </div>
  </div>
  <div class="btn-area mb-5">
							<button class="btn btn-primary" type="button" @click="handleDeleteChild">子ども削除</button>
						</div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { TMember } from '@/types/member';

const props = defineProps<{
    child: TMember,
    index: number,
    deleteChild: Function,
  }>()

  const child = ref<TMember>(props.child)
  const handleDeleteChild = () => {
    props.deleteChild(props.index)
  }

  
  configure({
    validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
    validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: false, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
  });
  
  const schema = Object.assign(
    {}, 
    {
      lastName: yup.string().required('氏名は必須項目です'),
      firstName: yup.string().required('氏名は必須項目です'),
      lastNameKana: yup.string().required('氏名(フリガナ)は必須項目です').matches(/^[\u30A1-\u30F6]+$/, '全角カタカナのみを入力してください'),
      firstNameKana: yup.string().required('氏名(フリガナ)は必須項目です').matches(/^[\u30A1-\u30F6]+$/, '全角カタカナのみを入力してください'),
      year: yup.string().required('年は必須項目です'),
      month: yup.string().required('月は必須項目です'),
      day: yup.string().required('日は必須項目です'),
        // [`${genderIndex}`]: yup.number().required('性別は必須項目です'),
      }
    )
  const { validate, errors, values } = useForm({ validationSchema: schema })
  
  const changeGender = (gender: number) => {
    child.value.gender = gender
  }

  const thisYear = new Date().getFullYear()

  const getLastDay = (year: number, month: number): number => {
    return new Date(year, month, 0).getDate()
  }

  const lastDay = computed(() => {
    if (child.value.year && child.value.month) {
      return getLastDay(Number(child.value.year), Number(child.value.month) )
    } else {
      return 31
    }
  })

  const childValid = ref()
  const childValidate = async() => {
    childValid.value = await validate()
    child.value.valid = childValid.value
  }
  defineExpose({
    childValidate,
  })

</script>