export const SEO = {
  DEFAULT: { title: 'YMCAポータル', description: 'YMCAの各種プログラム申込みサイトです。', keywords: 'キャンプ、水泳、英語、リトミック、自然、野外' },
  Root: { title: 'トップページ｜YMCAポータル', description: 'YMCAの各種プログラム申込みサイトです。夏、冬、春のシーズンプログラムお申し込みをお待ちしています。', keywords: 'キャンプ、水泳、英語、リトミック、自然、野外' },
  Top: { title: 'トップページ｜YMCAポータル', description: 'YMCAの各種プログラム申込みサイトです。夏、冬、春のシーズンプログラムお申し込みをお待ちしています。', keywords: 'キャンプ、水泳、英語、リトミック、自然、野外' },
  // NoticeDetail: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  SearchIndex: { title: 'プログラム検索｜YMCAポータル', description: 'YMCAで実施すYMCAポータルのプログラム検索画面です。るキャンプ等の予約サイトです。', keywords: 'YMCA、東京YMCA、キャンプ、スキー、水泳、英語' },
  // EntryIndex: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // EntryMail: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // EntryProfile: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // EntryComplete: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // LoginIndex: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypageIndex: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypageWithdrawal: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypageEventSchedule: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypageEventFavorite: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypageEventDetail: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypageEventDetailAfter: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypageProfileIndex: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypageProfileComplete: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypagePasswordForm: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // MypagePasswordComplete: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // EventDetail: { title: 'プログラム詳細｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // EventEntry: { title: 'プログラム申込｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // EventWaiting: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // EventConfirm: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // EventComplete: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // StaticBrowser: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // StaticCancel: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // StaticFaq: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // StaticPrivacy: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // StaticRecommendedEnvironment: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // StaticRule: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // PasswordIndex: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // PasswordMail: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // PasswordForm: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // PasswordComplete: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // InquiryIndex: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // InquiryComplete: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // RequestIndex: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // RequestComplete: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // Expired: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // Error: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
  // NotFound: { title: '｜YMCAポータル', description: 'YMCAで実施するキャンプ等の予約サイトです。', keywords: '' },
}
