<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">ご意見・ご要望</li>
    </ol>
  </nav>
  <div class="container">
    <div class="top--section">
      <h2>ご意見・ご要望</h2>
      <br />
      いつもYMCAポータルをご利用いただきありがとうございます。<br />
      サービス品質向上のため、お客様の声を募集しております。<br />
      お気づきの点がございましたら、 ぜひこちらからご意見・ご要望をお寄せください。<br />
      いただいたご意見・ご要望はすべて拝見しておりますが、返信はしておりません。<br />
      弊社からの返信をご希望の場合はお問い合わせフォームからお願いいたします。<br />
      <br />
        <div class="form-item occupation-detail">
          <div class="form-category-wrapper">
              <div class="form-category-inner">
                  <div class="form-category">ご意見・ご要望種別 <span class="badge bg-danger">必須</span></div>
              </div>
          </div>
          <div class="d-flex">
            <Field as="select" name="shop"  class="form-control form-select"  v-model="shop">
              <option value="" selected>選択してください</option>
              <option value="メール等の通知について">メール等の通知について</option>
              <option value="掲載内容が異なる等のご相談">掲載内容が異なる等のご相談</option>
              <option value="その他">その他</option>
            </Field>
          </div>
        </div>
        <div class="error-individual text-danger" id="type-err">{{ errors.shop }}</div><!-- /.error-individual -->
        <br />
        <div class="form-item self_pr">
          <div class="form-category-wrapper">
            <div class="form-category-inner">
              <div class="form-category">ご意見・ご要望の内容 <span class="badge bg-danger">必須</span></div>
            </div>
          </div>
          <div class="d-flex">
            <Field as="textarea" name="content" placeholder="" class="form-control" id="content" maxlength="1000" rows="5" v-model="content" />
          </div>
        </div>
        <div class="error-individual text-danger" id="contents-err">{{ errors.content }}</div><!-- /.error-individual -->
        <br />
        利用規約等・個人情報保護方針<br />
        ※下記の規約および個人情報保護方針を必ずお読みいいただき、同意の上回答を送信ください。<br />
        <br />
        <router-link to="/static/privacy">プライバシーポリシー</router-link><br />
        <router-link to="/static/rule">利用規約</router-link><br />
        <br />
        <div class="text-center">
          <button @click="sendRequest" class="btn btn-primary js-submit">送信する</button>
        </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { configure, useForm, useField, Field } from 'vee-validate'
import { onMounted } from 'vue'
import * as yup from 'yup'
import { useRouter } from 'vue-router'
import { useLoadingStore } from '@/stores/loading'
import { API } from '../../api'
import { handleErrors } from '@/config/handleErrors'

const router = useRouter()

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
})

const schema = yup.object({
  shop: yup.string().required('ご意見・ご要望種別は必須項目です'),
  content: yup.string().required('ご意見・ご要望の内容は必須項目です').max(1000,'ご意見・ご要望の内容は1000文字以内で入力してください'),
})

const { validate, errors, values } = useForm({ validationSchema: schema })

const { value: shop } = useField<string>('shop')
const { value: content } = useField<string>('content')

// const shops = ref<[]>([])

const canRegister = () => {
  if (shop.value && content.value) return true
}

onMounted(async() => {
  try {
    await API.get('/api/request/index').then(response => {
        })  
  } catch(e: any) {
    handleErrors(e)
  }
})


const sendRequest = async () => {
  const valid = await validate()
  if (!valid.valid) return
  try {
    useLoadingStore().start()
    API.post('/api/request/send', { 
      shop: shop.value,
      content: content.value,
      }).then(res => {
        if (res.data.status === 'success') {
          console.debug('送信成功')
          router.push('/request/complete')
        } else {
          console.debug('送信失敗')
        }
      })
    } catch(e: any) {
      handleErrors(e)
    } finally {
      useLoadingStore().stop()
    }
}
</script>
