<template>
    <div v-html="content.replace(/\n/g, '<br>')"></div>
</template>

<script setup>
import { API } from '../../api'
import { ref, onMounted } from 'vue'

const content = ref('')

onMounted(async () => {
    try {
        const response = await API.get('/api/master/2')
        content.value = response.data.content
    } catch (error) {
        console.error('データの取得に失敗しました:', error)
    }
})
</script>
