<template>
  <div class="form-check form-check-inline" v-for="option in options" :key="option.id">
    <input class="form-check-input" type="radio" :name="`inputSex-child-${props.memberId}-${surveyId}`" :id="`radio-${props.memberId}-${option.id}`" v-model="choice" :value="option">
    <label class="form-check-label" :for="`radio-${props.memberId}-${option.id}`">{{ option.label }}</label>
  </div>
</template>

<script setup lang="ts">
  import { TChoice, TAnswer } from '@/types/event'
  import { ref, watch } from 'vue'

  const props = defineProps<{
    memberId: number
  }>()

  const options = defineModel<TChoice[]>('options')
  const choices = defineModel<TChoice[]>('choices')
  const answers = defineModel<TAnswer[]>('answers')
  const surveyId = defineModel<number>('surveyId')
  const choice = ref()
  watch(() => choice.value, (newValue) => {
    choice.value = newValue
    if (choices.value !== undefined && answers.value !== undefined) {
      const existingIndex = answers.value.findIndex(choice => choice.surveyId === newValue.surveyId)
      if (existingIndex !== -1) {
        choices.value.splice(existingIndex, 1, newValue)
        answers.value.splice(existingIndex, 1, TAnswer.fromData(0, newValue.surveyId, props.memberId, newValue.id, null))
      } else {
        choices.value.push(newValue)
        answers.value.push(TAnswer.fromData(0, newValue.surveyId, props.memberId, newValue.id, null))
      }
    }
  })
</script>