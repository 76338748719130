<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/search/index">プログラム検索</router-link></li>
      <li class="breadcrumb-item" v-if="event"><router-link
          :to="{ name: 'EventDetail', params: { path: event.path } }">プログラム詳細</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">プログラム予約申込</li>
    </ol>
  </nav>
  <div class="container" v-if="event?.isDisplay">
    <h2 v-if="isWaiting">プログラム予約申込確認</h2>
    <h2 v-else>キャンセル待ちプログラム予約申込確認</h2>
    <div class="entry-form-inner">
      <p class="mt-3">プログラム名、料金をご確認の上、確認ボタンをクリックしてください。</p>
      <div class="table-wrap">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03 tblTit">プログラムコード</th>
              <td>{{ event?.id }}</td>
            </tr>
            <tr>
              <th class="tblBg03">プログラム名</th>
              <td>{{ event?.title }}</td>
            </tr>
            <tr>
              <th class="tblBg03">開催日</th>
              <td>
                <template v-for="schedule in eventSchedules" :key="schedule.id">
                  <div class="row m-0" v-if="schedule.isChecked">
                    <div class="form-check">
                      <template v-if="schedule.startAt && schedule.endAt && schedule.startAt === schedule.endAt">
                        <label class="form-check-label">{{ toDateSlash(schedule.startAt) }} </label>
                        <label class="form-check-label" v-if="event">{{
        displayReceptionStatus(event?.participants.find(p => p.eventCode === schedule.eventCode))
      }}</label>
                      </template>
                      <template v-else-if="schedule.startAt && schedule.endAt">
                        <label class="form-check-label">{{ toDateSlash(schedule.startAt) }}{{ schedule.endAt ? '〜' +
        toDateSlash(schedule.endAt) : '' }} </label>
                        <label class="form-check-label" v-if="event">{{
        displayReceptionStatus(event?.participants.find(p => p.eventCode === schedule.eventCode))
      }}</label>
                      </template>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
            <tr>
              <th class="tblBg03">基本費用合計</th>
              <td>{{ basicTotal.toLocaleString() }}{{ basicTotal ? '円' : '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-wrap">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03">参加者選択</th>
              <td>
                <template v-for="rental in rentals" :key="rental.id">
                  <div class="row m-0" v-if="rental.member.willAttend">
                    <div class="form-check">
                      <label class="form-check-label">{{ rental.member.firstName }}</label>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-for="rental in rentals" :key="rental.id">
        <div class="table-wrap option-color" v-if="rental.member.willAttend">
          <table class="normal mgT20" cellspacing="0">
            <tbody>
              <tr>
                <th colspan="2" class="tblBg03">{{ rental.member.firstName }}さんの確認 / オプション</th>
              </tr>
              <template v-for="survey in surveys" :key="survey.id">
                <tr v-if="survey.type && survey.type === 1">
                  <th class="tblBg03">{{ survey.content }}</th>
                  <td>
                    <label class="form-check-label">
                      {{ choiceLabels(rental, survey) }}
                    </label>
                  </td>
                </tr>
                <tr v-else>
                  <th class="tblBg03">{{ survey.content }}</th>
                  <td>
                    <!-- <label class="form-check-label">{{ rental.choice.label }}</label> -->
                    <!-- <label class="form-check-label">{{ choiceLabel(rental.choices, rental.answers) }}</label> -->
                    <label class="form-check-label">{{ choiceLabel(rental, survey) }}</label>
                  </td>
                </tr>
              </template>
              <tr>
                <th class="tblBg03">{{ rental.member.firstName }}オプション費用小計</th>
                <td class="d-flex justify-content-end fw-bold"><span class="subtotal">¥ {{ rental.getTotalChoicesValue()
                    }}</span>
                </td>
                <!-- <td class="d-flex justify-content-end fw-bold"><span class="subtotal">¥ {{ calcTotal(choices) }}</span></td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <div class="table-wrap total-color">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03">費用合計</th>
              <td class="d-flex justify-content-end fw-bold"><span class="total">¥ {{ calcTotal.toLocaleString()
                  }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="cancel-wrap">
        <div class="cancel-innder">
          <CancelPolicy />
        </div>
        <div class="cancel-innder mt-4">
          <Confirmation />
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="cancel-check" v-model="readFlg"><label
            for="cancel-check">キャンセル規定・予約前確認事項に同意する</label>
        </div>
      </div>
      <div class="btn-area">
        <button class="btn btn-back" @click="$router.go(-1)">戻る</button>
        <button type="button" class="btn btn-apply red" @click="handleSave()" v-if="!isWaiting"
          :disabled="!readFlg">キャンセル待ちする確定</button>
        <button type="button" class="btn btn-apply red" @click="handleSave()" v-else-if="readFlg">確定</button>
        <button type="button" class="btn btn-apply red" @click="handleSave()" disabled v-else>確定</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { TEvent, TEventMembership, TEventTarget, TEventSchedule, TPaymentMethod, TSurvey, TChoice, TAnswer, TRental, TEventParticipant } from '@/types/event'
import { TMember } from '@/types/member'
import { useEventStore } from '@/stores/event'
import { toDateSlash, displayReceptionStatus } from '@/js/util'
import { useMemberStore } from '@/stores/member'
import { useLoadingStore } from '@/stores/loading'
import CancelPolicy from '@/components/Static/CancelPolicy.vue'
import Confirmation from '@/components/Static/Confirmation.vue'
import { handleErrors } from '@/config/handleErrors'

const router = useRouter()

const event = ref<TEvent>()
const eventSchedules = ref<TEventSchedule[]>()
const paymentMethods = ref<TPaymentMethod[]>()
const parent = ref<TMember>()
const children = ref<TMember[]>()
const surveys = ref<TSurvey[]>()
const readFlg = ref<boolean>(false)

const paymentId = ref<number>(0)

const rentals = ref<TRental[]>([])

// const isRead = computed(()=>  {
//   return readFlg
// })

onMounted(async () => {
  const data: any = await useEventStore().getEventConfirm()
  event.value = data.event
  parent.value = data.parent
  children.value = data.children
  eventSchedules.value = data.schedules
  paymentMethods.value = data.paymentMethods
  surveys.value = data.surveys
  rentals.value = data.rentals.map((rentalData: any) => new TRental(rentalData))
  paymentId.value = data.paymentId

  if (event.value) {
    if (!event.value.isApply) {
      router.push({ name: 'Top' })
    }
  }
})

const isWaiting = computed(() => {
  for (const schedule of eventSchedules.value ? eventSchedules.value : []) {
    if (schedule.isChecked) {
      const participant = event.value?.participants.find(p => p.eventCode === schedule.eventCode)
      if (participant?.isApplicable)
        return true
    }
  }
  return false
})

const basicTotal = computed(() => {
  let total = 0
  for (const rental of rentals.value) {
    if (event.value?.priceDetail && rental.member.willAttend) total += event.value?.priceDetail
  }
  return total
})

const calcTotal = computed(() => {
  let total = 0
  total += basicTotal.value
  for (const rental of rentals.value) {
    // if (event.value?.priceDetail && rental.member.willAttend) total += event.value?.priceDetail
    if (rental && rental.member.willAttend) total += rental.getTotalChoicesValue()
  }
  let participateSchedules
  if (eventSchedules.value) {
    participateSchedules = eventSchedules.value?.filter(s => s.isChecked === true)
  }
  return total * (participateSchedules?.length ?? 0)
})

const choiceLabel = computed(() => (rental: TRental, survey: TSurvey): string => {
  let text = ""
  for (const answer of rental.answers) {
    const choice = survey.choices.find(c => c.id === answer.choiceId)
    if (choice) return choice.label
    if (survey.id == answer.surveyId) text = answer.textAnswer!
  }
  if (text) return text
  return ''
})

const choiceLabels = computed(() => (rental: TRental, survey: TSurvey): string => {
  const checks = rental.choices.filter(c => c.surveyId === survey.id)
  let text = ''
  for (let c of checks) {
    text = text.concat(c.label).concat(' ')
  }
  return text
})

const handleSave = async () => {
  useLoadingStore().start()
  const data = {
    eventArg: event.value,
    parentArg: parent.value,
    childrenArg: children.value,
    schedulesArg: eventSchedules.value,
    paymentMethodsArg: paymentMethods.value,
    surveysArg: surveys.value,
    rentalsArg: rentals.value,
    paymentIdArg: paymentId.value
  }
  try {
    const result = await TEvent.save(data).then(
      result => {
        if (result === 'success') {
          useEventStore().clearEvent()
          router.push({ name: 'EventComplete' })
        }
      }
    )
  } catch (e: any) {
    handleErrors(e)
  }
}

</script>
<style scoped>
.scrollable-section {
  width: 100%;
  height: 300px;
  /* 必要に応じて高さを調整 */
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}
</style>
