<template>
  <div class="top--messageArea-wrap" v-if="notices && notices.length > 0">
    <div class="top--messageArea">
      <h2>お知らせ</h2>
      <div class="top--messageArea-list">
        <ul class="top--messageArea-list-inner">
          <template v-for="notice in notices" :key="notice.id">
            <li :class="isNew(notice.displayStartAt) ? 'new-icon' : ''">
              <template v-if="notice.content">
                <router-link :to="{ name: 'NoticeDetail', params: { path: notice.path } }">
                  <div class="top--messageArea-list-item">
                    <span class="date" v-if="notice.displayStartAt">{{ toDateDotJst(notice.displayStartAt) }}</span>
                    <span class="text">{{ notice.title }}</span>
                  </div>
                </router-link>
              </template>
              <template v-else>
                <div class="top--messageArea-list-item">
                  <span class="date" v-if="notice.displayStartAt">{{ toDateDotJst(notice.displayStartAt) }}</span>
                  <span class="text">{{ notice.title }}</span>
                </div>
              </template>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
  <!-- <section class="program_list program_list_short">
    <div class="program_list_inner">
      <h2>シーズンプログラム一覧</h2>
      <p>過去実施プログラム一覧</p>
      <div class="program_list_a_wapper">
        <router-link :to="{ name: 'EventList', params: { 'fiscal_year': 2024, 'category': 1} }"
          class="btn btn-sm btn-primary btn-top btn-program">2024夏プログラムを見る
        </router-link>
      </div>
    </div>
  </section> -->
  <section class="program_list">
    <div class="program_list_inner">
      <h2>シーズンプログラム一覧</h2>
      <p>公開中プログラム一覧</p>
      <div class="program_list_a_wapper">
        <div class="program_button">
          <!-- <a href="/interview/" class="btn btn-sm btn-primary btn-top btn-program">2024冬プログラムを見る</a> -->
          <router-link :to="{ name: 'EventList', params: { 'fiscal_year': 2024, 'category': 2} }"
          class="btn btn-sm btn-primary btn-top btn-program">2024年度　冬プログラムを見る
        </router-link>
        </div>
        <div class="program_button">
          <!-- <a href="/interview/" class="btn btn-sm btn-primary btn-top btn-program">2024春プログラムを見る</a> -->
          <router-link :to="{ name: 'EventList', params: { 'fiscal_year': 2024, 'category': 3} }"
          class="btn btn-sm btn-primary btn-top btn-program">2024年度　春プログラムを見る
        </router-link>
        </div>
      </div><!-- .program_list_a_wapper -->
      <p class="mt-4">過去実施プログラム一覧</p>
      <div class="program_list_a_wapper">
        <!-- <a href="/interview/" class="btn btn-sm btn-primary btn-top btn-program">2024夏プログラムを見る</a> -->
        <router-link :to="{ name: 'EventList', params: { 'fiscal_year': 2024, 'category': 1} }"
          class="btn btn-sm btn-primary btn-top btn-program">2024年度　夏プログラムを見る
        </router-link>
      </div><!-- .program_list_a_wapper -->
    </div><!-- .program_list_inner -->
  </section>
  <div class="container">
    <template v-for="events in allEvents">
      <template v-if="events && events.length > 0">
        <h2 class="mt-0 mt-4" v-if="events && events.length > 0 && events[0].categoryType">{{
    events[0].categoryType.name }}</h2>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3">
          <template v-for="event in events" :key="event.id">
            <div class="col">
              <div class="card shadow-sm">
                <!-- <img src="@/images/sample1.jpg"/> -->
                <img v-if="event.images && event.images.length > 0" :src="event.images[0].url" />
                <img v-else src="@assets/images/blank.gif" />
                <!-- <img v-else src="@/images/sample1.jpg"/> -->
                <div class="card-body top-list">
                  <div class="card-title">{{ event.title }}</div>
                  <p class="card-text mb-1">
                    開催期間： {{ earliestStartDate(event.schedules) }} ~ {{ latestEndDate(event.schedules) }}<br />
                    対象： {{ minTarget(event.targets) }} ~ {{ maxTarget(event.targets) }}
                  </p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <router-link :to="{ name: 'EventDetail', params: { path: event.path } }"
                        class="btn btn-sm btn-primary btn-top">詳細</router-link>
                    </div>
                    <small class="text-body-secondary" v-if="event.displayPeriodStart">{{
    toDateSlashJst(event.displayPeriodStart) }}公開</small>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="row" v-if="events && events.length > 0 && events[0].categoryType"><router-link
            :to="{ name: 'SearchIndex', query: { 'categories': events[0].category } }"
            class="btn btn-sm btn-primary mx-auto mt-4">more</router-link></div>
      </template>
    </template>

    <!--     
    <h2 class="mt-0 mt-4" v-if="seasonsEvents && seasonsEvents.length > 0 && seasonsEvents[0].categoryType">{{ seasonsEvents[0].categoryType.name}}プログラム</h2>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3">
      <template v-for="season in seasonsEvents" :key="season.id">
      <div class="col">
        <div class="card shadow-sm">
          <img src="@/images/sample1.jpg"/>
            <div class="card-body top-list">
              <p class="card-text mb-1">
              {{ season.title }}<br />
              開催期間： {{ earliestStartDate(season.schedules) }} ~ {{ latestEndDate(season.schedules) }}<br/>
            </p>
            <div class="d-flex justify-content-between align-items-center">
              <div class="btn-group">
                <router-link :to="{ name: 'EventDetail', params: { path: season.path} }" class="btn btn-sm btn-primary btn-top">詳細</router-link>
              </div>
              <small class="text-body-secondary" v-if="season.displayPeriodStart">{{ toDateSlashJst(season.displayPeriodStart) }}公開</small>
            </div>
          </div>
        </div>
      </div>
      </template>
    </div>
    <div class="row" v-if="seasonsEvents && seasonsEvents.length > 0 && seasonsEvents[0].categoryType"><a href="/event/detail.php" class="btn btn-sm btn-primary mx-auto mt-4">more</a></div>

    <h2 class="mt-5 mb-4" v-if="yearlyEvents && yearlyEvents.length > 0 && yearlyEvents[0].categoryType">{{ yearlyEvents[0].categoryType.name}}プログラム</h2>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3">
      <template v-for="yearlyEvent in yearlyEvents" :key="yearlyEvent.id">
      <div class="col">
        <div class="card shadow-sm">
          <img src="@/images/sample1.jpg"/>
              <div class="card-body top-list">
            <p class="card-text mb-1">
              {{ yearlyEvent.title }}<br />
              開催期間： {{ earliestStartDate(yearlyEvent.schedules) }} ~ {{ latestEndDate(yearlyEvent.schedules) }}<br/> 
            </p>
            <div class="d-flex justify-content-between align-items-center">
              <div class="btn-group">
                <router-link :to="{ name: 'EventDetail', params: { path: yearlyEvent.path} }" class="btn btn-sm btn-primary btn-top">詳細</router-link>
              </div>
              <small class="text-body-secondary" v-if="yearlyEvent.displayPeriodStart">{{ toDateSlashJst(yearlyEvent.displayPeriodStart) }}公開</small>
            </div>
          </div>
        </div>
      </div>
      </template>
    </div>
    <div class="row" v-if="yearlyEvents && yearlyEvents.length > 0 && yearlyEvents[0].categoryType"><a href="/event/detail.php" class="btn btn-sm btn-primary mx-auto mt-4">more</a></div>

    <h2 class="mt-5 mb-4" v-if="educationEvents && educationEvents.length > 0 && educationEvents[0].categoryType">{{ educationEvents[0].categoryType.name}}プログラム</h2>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3">
      <template v-for="education in educationEvents" :key="education.id">
      <div class="col">
        <div class="card shadow-sm">
          <img src="@/images/sample1.jpg"/>
          <div class="card-body top-list">
            <p class="card-text mb-1">
              {{ education.title }}<br />
              開催期間： {{ earliestStartDate(education.schedules) }} ~ {{ latestEndDate(education.schedules) }}<br />
            </p>
            <div class="d-flex justify-content-between align-items-center">
              <div class="btn-group">
                <router-link :to="{ name: 'EventDetail', params: { path: education.path} }" class="btn btn-sm btn-primary btn-top">詳細</router-link>
              </div>
              <small class="text-body-secondary" v-if="education.displayPeriodStart">{{ toDateSlashJst(education.displayPeriodStart) }}公開</small>
            </div>
          </div>
        </div>
      </div>
      </template>
    </div>
    <div class="row" v-if="educationEvents && educationEvents.length > 0 && educationEvents[0].categoryType"><a href="/event/detail.php" class="btn btn-sm btn-primary mx-auto mt-4">more</a></div>
 -->
  </div>
  <!-- <section class="program_list">
    <div class="program_list_inner">
      <h2>プログラム一覧</h2>
      <p>過去に実施したプログラムの一覧を確認することができます</p>
      <div class="program_list_a_wapper">
        <router-link :to="{ name: 'EventList', params: { 'fiscal_year': 2024, 'category': 1} }"
          class="btn btn-sm btn-primary btn-top">2024サマープログラムを見る
        </router-link>
      </div>
    </div>
  </section> -->
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { stringToDateSlash, toDateSlashJst, toDateDotJst, setSeo } from '@/js/util'
import { TEvent, TEventSchedule, TNotice } from '@/types/top'
import { TEventTarget } from '@/types/event'
import { TTargetType } from '@/types/search'
import { handleErrors } from '@/config/handleErrors'
import { useRoute } from 'vue-router'

const route = useRoute()

const events = ref<any>([])
const seasonsEvents = ref<TEvent[]>([])
const yearlyEvents = ref<TEvent[]>([])
const educationEvents = ref<TEvent[]>([])
const notices = ref<TNotice[]>([])
const allEvents = ref<TEvent[][]>([])

// const earliestStartDate = ref()
// const latestEndDate = ref()

onMounted(async () => {
  try {
    const {
      // seasonsEvents: season,
      // yearlyEvents: yearly,
      // educationEvents: education,
      notices: news,
      events: events
    } = await TEvent.fetch()
    // seasonsEvents.value = season
    // yearlyEvents.value = yearly
    // educationEvents.value = education
    notices.value = news
    allEvents.value = events
    console.log(allEvents.value)
  } catch (e: any) {
    handleErrors(e)
  }
})

const earliestStartDate = (schedules: TEventSchedule[]) => {
  if (schedules.length === 0) return null
  return toDateSlashJst(schedules.reduce((earliest, current) => {
    return current.startAt < earliest ? current.startAt : earliest
  }, schedules[0].startAt))
}
const latestEndDate = (schedules: TEventSchedule[]) => {
  if (schedules.length === 0) return null
  return toDateSlashJst(schedules.reduce((latest, current) => {
    return current.endAt > latest ? current.endAt : latest
  }, schedules[0].endAt))
}


const isNew = (day: Date): boolean => {
  const startDay = new Date(day)
  const today = new Date()
  const beforeWeek = new Date(today.setDate(today.getDate() - 7))
  return startDay >= beforeWeek
}
const minTarget = (eventTargets: TTargetType[]) => {
  if (eventTargets && eventTargets.length > 0) {
    return eventTargets.reduce((min: TTargetType, current: TTargetType) => min.id < current.id ? min : current).name
  } else {
    return ''
  }
}
const maxTarget = (eventTargets: TTargetType[]) => {
  if (eventTargets && eventTargets.length > 0) {
    return eventTargets.reduce((max, current) => max.id > current.id ? max : current).name
  } else {
    return ''
  }
}
</script>