<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">推奨環境</li>
    </ol>
  </nav>
  <div class="container">

      <div class="top--section">
          <h2>推奨環境</h2>
          <br />
           <p>公益財団法人東京YMCA（以下「当社」といいます）が運営、提供する「YMCAポータル」（以下「本サービス」といいます）のご利用にあたっては、以下の利用規約をご確認、ご承諾いただいたうえでお申込みくださいますようお願い致します。</p>
            <p>
<strong>第1条（本サービス）</strong><br>
「本サービス」とは、当社が運営するプラットフォームにおいて提供する予約サービス、の総称をいいます。</p>
<p>
<strong>第2条（メンバー）</strong><br>
1. 本サービスのメンバー（以下「メンバー」といいます」）とは、当社が定める方法により本サービスの利用申込みを行ない、当社がこれを承諾した利用者のことをいいます。<br>
2. メンバーは、本規約の内容を理解し、承諾した上で利用申込みを行うものとします。</p>
<p>
<strong>第3条（メンバーの責任）</strong><br>
1. メンバーは、自らの意思によってのみ本サービスに登録し、メンバーとなるものとします。<br>
2. メンバーは不備・齟齬がないように本サービスに自らの情報を正確に登録するものとします。登録情報の不備・齟齬により発生した事項については、当社は一切責任を負いません。</p>
<p>
<strong>第4条（ID及びパスワードの管理）</strong><br>
1. メンバーは、本サービスから付与されるID及びパスワードの管理責任を負うものとします。<br>
2. メンバーは、ID及びパスワードを第三者に利用させたり、貸与、譲渡、売買等をすることはできません。<br>
3. ID及びパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任はメンバーが負うものとし、当社は一切責任を負いません。</p>
<p>
<strong>第5条（登録の取り消し）</strong><br>
メンバーが以下のいずれかに該当した場合は、当社の判断にて登録の取り消しを行ない、本サービスの提供を終了する場合があります。<br>
(1) 本規約の定めに違反した場合<br>
(2) 虚偽の情報を提供または登録した場合<br>
(3) 正当な理由なく、本サービスからの連絡にご返信いただけない場合<br>
(4) 本サービスを通じて紹介を受けた求人企業に対し、当社の承諾なく直接連絡をとり、採用選考を受けるまたは入社する行為があった場合（求人広告に対する応募申込みなど、メンバーが直接連絡をとることを前提としているサービスは除く）<br>
(5) 正当な理由なく、本サービスを通じて紹介を受けた求人企業の面談もしくは選考試験・面接を事前の連絡なく欠席し、または採用内定を受諾後に辞退する等の行為があった場合<br>
(6) 求人企業、当社または第三者に不利益を与える行為、誹謗・中傷・苦情・差別発言等の不適切な行為、または名誉、信用を損なう行為があった場合<br>
(7) 犯罪に結びつく行為があった場合<br>
(8) 法令または公序良俗に反する行為があった場合<br>
(9) 本サービスを利用した営業活動、営利を目的とする情報提供行為があった場合<br>
(10) 本サービスの運営を妨げる行為があった場合<br>
(11) 本サービスの信用を毀損するような行為があった場合<br>
(12) 本サービスの範囲を超える不当な要望を繰り返し行なった場合<br>
(13) その他、客観的、合理的事由により当社が不適切と判断する行為があった場合</p>
<p>
<strong>第6条（職業紹介サービス）</strong><br>
1. 本サービスにてメンバーに提供する、一般には公開されていない求人案件情報及びその関連情報について、メンバーは第三者へ提供・開示等を行なえないものとします。<br>
2. 本サービスのうち、当社のコンサルタントが応募受付、面接日時の調整及び選考結果の連絡等を行なっている案件については、求人企業への連絡は原則全て当社が行ない、メンバー本人から求人企業への直接の連絡は行なうことはできません（但し、面接当日などの緊急連絡が必要な場合は、原則として直接連絡を行なっていただきます）。</p>
<p>
<strong>第7条（スカウトサービス）</strong><br>
1. メンバーは、本サービスへの登録をもって、次項に定めるスカウトサービスを利用することができるものとします。メンバーは、スカウトサービスの利用を希望しない場合は、本サービス所定の方法で、利用の解除を行うものとします。<br>
2. スカウトサービスは、メンバーによって登録された個人情報のうち、氏名、氏名フリガナ、生年月日、郵便番号、現住所(ただし都道府県、市区町村は公開)、最寄駅、携帯電話番号、メールアドレス、会社名等の除外項目を除く情報等について、当社が本人に代わって求人企業に開示のうえ閲覧させることによって、メンバーが求人企業からの求人オファーの受信を可能とするサービスをいいます。<br>
3. スカウトサービスでは、前項に定めた除外項目にかかる求人企業への開示は除外されますが、メンバーが職務内容や自己PR等の別の項目欄において除外項目に該当する個人情報を自ら記入した場合は、求人企業に提供されます。このため、メンバーは自己の責任で、かかる状況を回避するための対処を行うものとし、当社はメンバーが当該対処を行わなかった結果として生じたメンバーの不利益については、当社に故意・重過失がない限り、一切責任を負わないものとします。</p>
<p>
<strong>第8条（求人照合）</strong><br>
当社は本サービスの提供にあたり、メンバーの登録内容と、求人企業が希望する求人条件とのマッチングを行ないますが、マッチングの検討基準や判断理由等をお伝えすることはできません。また、メンバーから求人企業への応募依頼を受け付けた場合であっても、求人企業より示された選考基準などを参考にして当社において当該求人への適合度合い等を判断した結果、当該求人への推薦を行なわない場合や、求人企業に代わり求人条件に適合しない旨のご連絡を行なう場合があります。</p>
<p>
<strong>第9条（求人企業への事実確認）</strong><br>
1. 当社は、求人企業または職業紹介会社から通知される労働条件その他契約内容に関する情報と、メンバーから提供される当該情報に不一致がある場合、求人企業または職業紹介会社に事実確認を行なう場合があります。<br>
2. 当社は、本サービスの運営に必要な範囲で、メンバーの転職活動状況及び本サービスを通じて応募を行なった求人企業での入退社の状況等を、求人企業または職業紹介会社に確認する場合があります。</p>
<p>
<strong>第10条（個人情報の取り扱い）</strong><br>
当社は、別途定める「プライバシーポリシー」に従い、メンバーの個人情報を適切に取得・利用・提供・管理します。</p>
<p>
<strong>第11条（免責）</strong><br>
1. 当社に故意・重過失がない限り、以下の事項について当社は免責されます。<br>
(1) 本サービスは、メンバーの就職・転職を保証するものではないこと<br>
(2) 本サービスにおいて提供される求人情報は求人企業または職業紹介会社等の第三者の責任で提供されるものであり、当該情報の真実性、正確性、合法性、安全性、適切性及び有用性について当社は何ら保証できないこと<br>
(3) メンバーと求人企業または職業紹介会社等の第三者との間での紛争が生じた場合には、メンバーの責任と負担で当該第三者と協議・解決を図ること<br>
(4) メンバーは、自己の判断と責任に基づいて求人企業と雇用契約を結ぶこと<br>
(5) 天災地変、戦争、通信障害、ネットワーク障害、通常講ずるべきセキュリティ対策では防止できないウィルス被害、不正アクセスその他の不可抗力により発生した損害<br>
2. メンバーが本サービスを利用したことかつ当社の責めに帰すべき事由によってメンバーに損害が発生した場合、当社が責任を負うのはメンバーに直接かつ現実的に発生した損害の範囲に限定されるものとします。<br>
3. 当社は、本サービスの適正な運営及び管理のために必要な全ての権限を有するものとします。</p>
<p>
<strong>第12条（登録の抹消）</strong><br>
1. メンバーは自らの意思によりいつでも本サービスを退会することができます。<br>
2. 退会の意思がなくとも、本サービスに一定期間ログインがない場合は、自動的に登録を抹消される場合があります。</p>
<p>
<strong>第13条（損害賠償）</strong><br>
メンバーは、本規約の定めに違反して当社または第三者に損害を与えた場合は、その損害（弁護士費用を含みます。）を賠償するものとします。</p>
<p>
<strong>第14条（サービスの変更）</strong><br>
1. 当社は、メンバーへの事前の通知なくして、本サービスの内容変更または一時的な中断を行なうことができます。<br>
2. 当社は一定の予告期間をもってメンバーに通知のうえ、本サービスの提供を長期的に中断もしくは終了することができます。但し、やむを得ない事情がある場合は、予告を行なわない場合があります。<br>
3. 当社は以下のいずれかの場合に、本規約（第１０条に定める「プライバシーポリシー」を含みます）を変更することができます。<br>
(1) 本規約の変更が、メンバーの一般の利益に適合するとき。<br>
(2) 本規約の変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。<br>
4. 当社は前項による変更にあたって、Web上に掲示、あるいは当社が適当と判断する方法でメンバーに通知することにより、本規約を変更できるものとします。<br>
5. メンバーが、本規約の変更後に本サービスを利用したときは、本規約の変更に同意したものとみなします。</p>
<p>
<strong>第15条（反社会的勢力の排除）</strong><br>
メンバーは、本サービスを利用するにあたり、当社に対して次に定める事項を表明し、保証するものとします。また、かかる表明・保証に違反した場合、当社は催告を要することなく直ちに、本サービスの提供を打ち切り、契約及び申込みを解約できるものとします。<br>
(1) メンバーが反社会的勢力（暴力団暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業の関係者、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これらに準ずる者をいいます）又は反社会的勢力と社会的に非難されるべき関係を有していると認められる者ではないこと<br>
(2) 反社会的勢力を利用しないこと及びこれに準ずる行為をしないこと<br>
(3) 反社会的勢力に資金提供を行なわないこと及びこれに準ずる行為をしないこと<br>
(4) 反社会的勢力を名乗る等して当社又は求人企業または職業紹介会社等の第三者の名誉等の毀損、業務妨害、不当要求行為、またはこれに準ずる行為をしないこと</p>
<p>
<strong>第16条（管轄裁判所）</strong><br>
本規約に関する準拠法は日本法とし、本規約に関して生じる一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
<p>
本規約の制定日2022年9月20日</p>
          <hr>
      </div>
  </div>
</template>
