<template>
    <nav aria-label="breadcrumb" class="breadcrumb-area">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">お問い合せ</li>
        </ol>
    </nav>
    <div class="container">
        <div class="top--section">
            <h2>お問い合わせ</h2>
            <input type="hidden" name="csrf_token" value="" />
            <div class="form-item occupation-detail">
                <div class="form-category-wrapper">
                    <div class="form-category-inner">
                        <div class="form-category pl-1">氏名<span class="badge bg-danger">必須</span></div>
                    </div>
                </div>
                <div class="d-flex">
                    <Field type="text" name="name" placeholder="氏名" class="form-control" id="name"  maxlength="16" v-model="name"/>
                </div>
            </div>
            <div class="error-individual text-danger" id="name-err">{{ errors.name }}</div><!-- /.error-individual -->
            <br />
            <div class="form-item retype-password">
                <div class="form-category-wrapper">
                    <div class="form-category-inner">
                        <div class="form-category">電話番号（ハイフンなし）<span class="badge bg-danger">必須</span></div>
                    </div>
                </div>
                <div class="d-flex">
                    <Field type="tel" name="phoneNumber" placeholder="" class="form-control" id="inputTel" maxlength="13" v-model="phoneNumber" />
                </div>
            </div>
            <div class="error-individual text-danger" id="tel-err">{{ errors.phoneNumber }}</div><!-- /.error-individual -->
            <br />
            <div class="form-item occupation-detail">
                <div class="form-category-wrapper">
                    <div class="form-category-inner">
                        <div class="form-category">メールアドレス<span class="badge bg-danger">必須</span></div>
                    </div>
                </div>
                <div class="d-flex">
                    <Field name="mailAddress" type="email" class="form-control" id="floatingInput"
                placeholder="name@example.com" />
                </div>
            </div>
            <div class="error-individual text-danger" id="email-err">{{ errors.mailAddress }}</div><!-- /.error-individual -->
            <br />
            <div class="form-item occupation-detail">
                <div class="form-category-wrapper">
                    <div class="form-category-inner">
                        <div class="form-category">お問い合わせ種別 <span class="badge bg-danger">必須</span></div>
                    </div>
                </div>
                <div class="d-flex">
                    <Field as="select" name="shop"  class="form-control form-select"  v-model="shop">
                        <option value="">選択してください</option>
                        <option :value="1">登録、ログイン、メールアドレスについて</option>
                        <option :value="2">キャンプ・デイキャンプ・スキーキャンプについて</option>
                        <option :value="3">御殿山水泳について</option>
                        <option :value="4">東陽町水泳について</option>
                        <option :value="5">山手水泳について</option>
                        <option :value="6">語学プログラムについて</option>
                        <option :value="7">江東センターのプログラムについて</option>
                    </Field>
                </div>
            </div>
            <div class="error-individual text-danger" id="type-err">{{ errors.shop }}</div><!-- /.error-individual -->
            <br />
            <div class="form-item self_pr">
                <div class="form-category-wrapper">
                    <div class="form-category-inner">
                        <span class="text-danger badge pl-1"></span>
                        <div class="form-category">お問い合わせ内容 <span class="badge bg-danger">必須</span></div>
                    </div>
                </div>
                <div class="d-flex">
                    <Field as="textarea" name="content" placeholder="" class="form-control" id="content" rows="5" v-model="content" maxlength="1000" />
                </div>
            </div>
            <div class="error-individual text-danger" id="contents-err">{{ errors.content }}</div><!-- /.error-individual -->
            <br>
            <router-link to="/static/privacy" target="_blank">プライバシーポリシー</router-link>に同意の上、送信内容を確認してください。<br />
            <br />
            <button @click="sendInquiry" class="btn btn-primary">送信する</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { configure, useForm, useField, Field } from 'vee-validate'
import { onMounted } from 'vue'
import { ref } from 'vue'
import * as yup from 'yup'
import { useRouter } from 'vue-router'
import { useLoadingStore } from '@/stores/loading'
import { API } from '../../api'
import { handleErrors } from '@/config/handleErrors'

const router = useRouter()

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
})

const schema = yup.object({
  name: yup.string().required('氏名は必須項目です'),
  phoneNumber: yup.string().required('電話番号は必須項目です').max(13, '電話番号は13桁以内で入力してください').matches(/^\d{10,13}$/, { message: '全て数字で入力してください' }),
  mailAddress: yup.string().required('メールアドレスは必須項目です').email('メールアドレスの形式で入力してください'),
  shop: yup.string().required('お問い合わせ種別は必須項目です'),
  content: yup.string().required('お問い合わせ内容は必須項目です').max(1000,'お問い合わせ内容は1000文字以内で入力してください'),
})
const { validate, errors, values } = useForm({ validationSchema: schema })

const { value: name } = useField<string>('name')
const { value: phoneNumber } = useField<string>('phoneNumber')
const { value: mailAddress } = useField<string>('mailAddress')
const { value: shop } = useField<string>('shop')
const { value: content } = useField<string>('content')
const shops = ref<[]>([])

const terms = ref(false)
const privacy = ref(false)

const canRegister = () => {
  if (name.value && phoneNumber.value && mailAddress.value && shop.value && content.value) return true
}


const sendInquiry = async () => {
  const valid = await validate()
  if (!valid.valid) return
  useLoadingStore().start()
  try {
    API.post('/api/inquiry/send', { 
      name: name.value,
      phoneNumber: phoneNumber.value,
      mailAddress: mailAddress.value,
      shop: shop.value,
      content: content.value,
      }) .then(res => {
        useLoadingStore().stop()
        if (res.data.status === 'success') {
          console.debug('送信成功')
          router.push('/inquiry/complete')
        } else {
          console.debug('送信失敗')
        }
      })
    } catch(e: any) {
      handleErrors(e)
    } finally {
      useLoadingStore().stop()
    }
}
</script>
