<template>
  <select class="form-control form-select" v-model="choice">
    <option disabled :value="initial">{{ initial.label }}</option>
    <option v-for="option in options" :key="option.id" :value="option">{{ option.label }}</option>
  </select>
</template>

<script setup lang="ts">
  import { TChoice, TAnswer } from '@/types/event'
  import { onMounted, ref, watch } from 'vue'

  const props = defineProps<{
    memberId: number
  }>()

  const options = defineModel<TChoice[]>('options')
  const choices = defineModel<TChoice[]>('choices')
  const answers = defineModel<TAnswer[]>('answers')
  const surveyId = defineModel<number>('surveyId')
  const choice = ref<TChoice>(new TChoice({id: 0,survey_id: 0,label: '▼をクリックしてお選びください', value:0}))
  const initial: TChoice = new TChoice({id: 0,survey_id: 0,label: '▼をクリックしてお選びください', value:0})
  // onMounted(async () => {
  //   if (choices.value) {
  //     choices.value.push(new TChoice({id: 0,surveyId: 0,label: '▼をクリックしてお選びください', value:0}))
  //   } else {
  //     choices.value = [new TChoice({id: 0,surveyId: 0,label: '▼をクリックしてお選びください', value:0})]
  //   }
  // })
  watch(() => choice.value, (newValue) => {
    choice.value = newValue
    if (choices.value !== undefined && answers.value !== undefined) {
      const existingIndex = choices.value.findIndex(choice => choice.surveyId === newValue.surveyId)
      if (existingIndex !== -1) {
        choices.value.splice(existingIndex, 1, newValue)
        answers.value.splice(existingIndex, 1, TAnswer.fromData(0, newValue.surveyId, props.memberId, newValue.id, null))
      } else {
        choices.value.push(newValue)
        answers.value.push(TAnswer.fromData(0, newValue.surveyId, props.memberId, newValue.id, null))
      }
    }
  })
</script>