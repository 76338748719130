<template>
  <template v-if="parent.mailAddress">
    <nav aria-label="breadcrumb" class="breadcrumb-area">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">メンバー登録 -プロフィール入力-</li>
      </ol>
    </nav>
    <div class="container">
      <h1>メンバー登録 -プロフィール入力-</h1>
      <div class="top--section">
        <div class="mb-3 row">
          <label for="inputEmail" class="col-sm-4 col-form-label">メールアドレス&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="col-sm-6">
            <p class="form-control-static">{{ parent.mailAddress }}</p>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputPassword" class="col-sm-4 col-form-label">パスワード&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span><br>(半角英数字8文字以上)</label>
          <div class="col-sm-6">
            <Field type="password" name="password" placeholder="password" class="form-control" id="inputPassword"
              v-model="parent.password" />
            <div class="text-danger form-error">{{ errors.password }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputSei" class="col-sm-4 col-form-label">氏名&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span><br>(保護者または成人)</label>
          <div class="row col-sm-6">
            <div class="col-sm-6 pe-0">
              <Field type="text" name="lastName" placeholder="姓" class="form-control" id="inputSei" maxlength="16"
                v-model="parent.lastName" />
            </div>
            <div class="col-sm-6 pe-0">
              <Field type="text" name="firstName" placeholder="名" class="form-control" id="inputMei" maxlength="16"
                v-model="parent.firstName" />
            </div>
            <div class="col-12 text-danger form-error">{{ errors.lastName || errors.firstName }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputSeiKana" class="col-sm-4 col-form-label">氏名(フリガナ)&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="row col-sm-6">
            <div class="col-sm-6 pe-0">
              <Field type="text" name="lastNameKana" placeholder="セイ" class="form-control" id="inputSeiKana"
                maxlength="16" v-model="parent.lastNameKana" />
            </div>
            <div class="col-sm-6 pe-0">
              <Field type="text" name="firstNameKana" placeholder="メイ" class="form-control" id="inputMeiKana"
                maxlength="16" v-model="parent.firstNameKana" />
            </div>
            <div class="col-12 text-danger form-error">{{ errors.lastNameKana || errors.firstNameKana }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputBirthday" class="col-sm-4 col-form-label">生年月日 &nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="col-sm-8 d-flex">
            <div class="col-sm-4">
              <div class="input-group">
                <Field as="select" name="year" id="inputBirthday" class="form-control form-select"
                  v-model="parent.year">
                  <option value=""></option>
                  <option v-for="y in 100" :key="y" :value="((thisYear + 1) - y).toString()">{{ (thisYear + 1) - y }}
                  </option>
                </Field>
                <div class="input-group-prepend">
                  <div class="input-group-text">年</div>
                </div>
                <div class="col-12 text-danger form-error">{{ errors.year }}</div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="input-group">
                <Field as="select" name="month" class="form-control form-select" v-model="parent.month">
                  <option value=""></option>
                  <option v-for="m in 12" :key="m" :value="m.toString()">{{ m }}</option>
                </Field>
                <div class="input-group-prepend">
                  <div class="input-group-text">月</div>
                </div>
                <div class="col-12 text-danger form-error">{{ errors.month }}</div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="input-group">
                <Field as="select" name="day" class="form-control form-select" v-model="parent.day">
                  <option value=""></option>
                  <option v-for="d in lastDay" :key="d" :value="d.toString()">{{ d }}</option>
                </Field>
                <div class="input-group-prepend">
                  <div class="input-group-text">日</div>
                </div>
                <div class="col-12 text-danger form-error">{{ errors.day }}</div>
              </div>
            </div>
          </div>
          <label for="birthdayError" class="col-sm-4 col-form-label"></label>
          <div class="col-sm-6">
            <div class="text-danger col-12 form-error" id="birthdayError">{{ errors.birthday }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputGenderParent" class="col-sm-4 col-form-label">性別&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="col-sm-8 mt-2">
            <div class="form-check form-check-inline">
              <Field type="radio" id="gender-man" name="gender" :value=1 class="form-check-input" v-model="parent.gender">
              </Field>
              <label class="form-check-label" :for="`gender-man`">男性</label>
            </div>
            <div class="form-check form-check-inline">
              <Field type="radio" id="gender-woman" name="gender" :value=2 class="form-check-input" v-model="parent.gender">
              </Field>
              <label class="form-check-label" :for="`gender-woman`">女性</label>
            </div>
            <div class="col-12 text-danger form-error">{{ errors[`gender`] }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputTel" class="col-sm-4 col-form-label">電話番号&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="col-sm-6">
            <Field type="tel" name="phoneNumber" placeholder="09009871234" class="form-control" id="inputTel"
              maxlength="13" v-model="parent.phoneNumber" />
            <div class="text-danger form-error">{{ errors.phoneNumber }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="postalCode" class="col-sm-4 col-form-label">郵便番号&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="col-sm-3">
            <Field type="text" name="postalCode" placeholder="1236789" class="form-control" id="postalCode"
              maxlength="7" v-model="parent.postalCode" @change="getAddress" />
            <div class="text-danger form-error">{{ errors.postalCode }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputPrefecture" class="col-sm-4 col-form-label">都道府県&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="col-sm-3">
            <Field as="select" name="prefecture" class="form-select" id="inputPrefecture" v-model="parent.prefecture">
              <option value=""></option>
              <option value="北海道">北海道</option>
              <option value="青森県">青森県</option>
              <option value="岩手県">岩手県</option>
              <option value="宮城県">宮城県</option>
              <option value="秋田県">秋田県</option>
              <option value="山形県">山形県</option>
              <option value="福島県">福島県</option>
              <option value="茨城県">茨城県</option>
              <option value="栃木県">栃木県</option>
              <option value="群馬県">群馬県</option>
              <option value="埼玉県">埼玉県</option>
              <option value="千葉県">千葉県</option>
              <option value="東京都">東京都</option>
              <option value="神奈川県">神奈川県</option>
              <option value="新潟県">新潟県</option>
              <option value="富山県">富山県</option>
              <option value="石川県">石川県</option>
              <option value="福井県">福井県</option>
              <option value="山梨県">山梨県</option>
              <option value="長野県">長野県</option>
              <option value="岐阜県">岐阜県</option>
              <option value="静岡県">静岡県</option>
              <option value="愛知県">愛知県</option>
              <option value="三重県">三重県</option>
              <option value="滋賀県">滋賀県</option>
              <option value="京都府">京都府</option>
              <option value="大阪府">大阪府</option>
              <option value="兵庫県">兵庫県</option>
              <option value="奈良県">奈良県</option>
              <option value="和歌山県">和歌山県</option>
              <option value="鳥取県">鳥取県</option>
              <option value="島根県">島根県</option>
              <option value="岡山県">岡山県</option>
              <option value="広島県">広島県</option>
              <option value="山口県">山口県</option>
              <option value="徳島県">徳島県</option>
              <option value="香川県">香川県</option>
              <option value="愛媛県">愛媛県</option>
              <option value="高知県">高知県</option>
              <option value="福岡県">福岡県</option>
              <option value="佐賀県">佐賀県</option>
              <option value="長崎県">長崎県</option>
              <option value="熊本県">熊本県</option>
              <option value="大分県">大分県</option>
              <option value="宮崎県">宮崎県</option>
              <option value="鹿児島県">鹿児島県</option>
              <option value="沖縄県">沖縄県</option>
            </Field>
            <div class="text-danger form-error">{{ errors.prefecture }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputCity" class="col-sm-4 col-form-label">市区町村&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="col-sm-6">
            <Field type="text" name="city" placeholder="千代田区" class="form-control" id="inputCity" maxlength="20"
              v-model="parent.city" />
            <div class="text-danger form-error">{{ errors.city }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputAddress" class="col-sm-4 col-form-label">番地&nbsp;&nbsp;<span
              class="badge bg-danger">必須</span></label>
          <div class="col-sm-6">
            <Field type="text" name="streetAddress" placeholder="千代田1-1" class="form-control" id="inputAddress"
              maxlength="20" v-model="parent.streetAddress" />
            <div class="text-danger form-error">{{ errors.streetAddress }}</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputBuilding" class="col-sm-4 col-form-label">建物名・部屋番号</label>
          <div class="col-sm-6">
            <Field type="text" name="buildingName" placeholder="コーポ101" class="form-control" id="inputBuilding"
              maxlength="100" v-model="parent.buildingName" />
            <div class="text-danger form-error">{{ errors.buildingName }}</div>
          </div>
        </div>
        <div class="text-danger" style="align-items: center;display: flex;flex-direction: column;"
          v-if="duplicateMessage">{{ duplicateMessage }}</div>
        <div class="btn-area mb-5">
          <!-- <button class="btn btn-apply" type="button" @click="handleToConfirm()">登録確認へ</button> -->
          <button class="btn btn-apply" type="button" @click="handleToChild()">次へ</button>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { Ref, computed, reactive, ref } from 'vue'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { API } from '../../api'
import { useLoadingStore } from '@/stores/loading'
import ChildComponent from './child.vue'
import { TMember } from '@/types/member'
import { useMemberStore } from '@/stores/member'
import { useEntryStore } from '@/stores/entry'
import jsonpAdapter from 'axios-jsonp'
import { handleErrors } from '@/config/handleErrors'
import { getAge } from '@/js/util'

const router = useRouter()
const thisYear = new Date().getFullYear()
const memberStore = useMemberStore()
const parent = ref<TMember>(TMember.createEmptyMember())
useLoadingStore().start()

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

const schema = yup.object({
  // mail_address: yup.string().required('ユーザーIDは必須項目です').email('メールアドレスの形式で入力してください'),
  password: yup.string().required('パスワードは必須項目です').matches(/^[A-Za-z0-9]+$/, '半角英数字で入力してください').min(8, '8文字以上で入力してください'),
  lastName: yup.string().required('氏名は必須項目です').max(16, '氏名は16文字以内で入力してください'),
  firstName: yup.string().required('氏名は必須項目です').max(16, '氏名は16文字以内で入力してください'),
  lastNameKana: yup.string().required('氏名(フリガナ)は必須項目です').max(16, '氏名(フリガナ)は16文字以内で入力してください').matches(/^[\u30A1-\u30F6ー]+$/, '全角カタカナのみを入力してください'),
  firstNameKana: yup.string().required('氏名(フリガナ)は必須項目です').max(16, '氏名(フリガナ)は16文字以内で入力してください').matches(/^[\u30A1-\u30F6ー]+$/, '全角カタカナのみを入力してください'),
  year: yup.string().required('年は必須項目です'),
  month: yup.string().required('月は必須項目です'),
  day: yup.string().required('日は必須項目です'),
  gender: yup.number().required('性別は必須項目です'),
  phoneNumber: yup.string().required('電話番号は必須項目です').max(13, '電話番号は13桁以内で入力してください').matches(/^\d{10,13}$/, { message: '全て数字で13桁以内で入力してください' }),
  postalCode: yup.string().required('郵便番号は必須項目です').max(7, '郵便番号は7桁以内で入力してください').matches(/^\d{7}$/, { message: '半角数字7桁で入力してください', excludeEmptyString: true }).nullable(),
  prefecture: yup.string().required('都道府県は必須項目です'),
  city: yup.string().required('市区町村は必須項目です').max(20, '市区町村は20文字以内で入力してください'),
  streetAddress: yup.string().required('番地は必須項目です').max(20, '番地は20文字以内で入力してください'),
  buildingName: yup.string().max(100, '建物名は100文字以内で入力してください').nullable(),
  birthday: yup.date().test('birthday', '保護者年齢に該当しません', function (value) {
    if (year.value && month.value && day.value) {
      const birthday = new Date(Number(year.value), Number(month.value) - 1, Number(day.value))
      return getAge(birthday) >= 18
    } else {
      return true
    }
  })
})

// const schemaWithAgeValidation = schema.test('birthday', '保護者年齢に該当しません', function(value) {
//   if (year.value && month.value && day.value) {
//     const birthday = new Date(Number(year.value), Number(month.value) - 1, Number(day.value)); // 誕生日を Date オブジェクトに変換
//     console.log(birthday)
//     console.log(getAge(birthday))
//     return getAge(birthday) >= 18
//   }
//   return true; // 値が未定義の場合はバリデーションをスキップ
// })

const { validate, errors, values, handleSubmit } = useForm({ validationSchema: schema });
// const mailAddress = atob(router.currentRoute.value.query.address!.toString())
const mailAddress = ref('')
const { value: year } = useField<string>('year')
const { value: month } = useField<string>('month')
const { value: day } = useField<string>('day')
const { value: gender } = useField<number>('gender')
const duplicateMessage = ref<string>('')

const getLastDay = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate()
}

const lastDay = computed(() => {
  if (year.value && month.value) {
    return getLastDay(Number(year.value), Number(month.value))
  } else {
    return 31
  }
})

const getAddress = async () => {
  await API.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${parent.value.postalCode}`, { adapter: jsonpAdapter }).then(r => {
    if (r.data.status === 200 && r.data.results && r.data.results.length > 0) {
      const result = r.data.results[0]
      parent.value.prefecture = result.address1
      parent.value.city = result.address2
      parent.value.streetAddress = result.address3
    } else {
      parent.value.prefecture = ''
      parent.value.city = ''
      parent.value.streetAddress = ''
    }
  })
}

const children = ref<TMember[]>([])

// const handleStore = async () => {
//   try {
//     useLoadingStore().start()
//     const valid = await validate()
//     if (!valid.valid) {
//       useLoadingStore().stop()
//       return
//     }
//     children.value = []
//     TMember.storeEntry({ parent: parent.value, children: children.value }).then(response => {
//       useLoadingStore().stop()
//       if (response.data.status == "success") {
//         router.push('/entry/complete')
//       }
//       else {
//         duplicateMessage.value = response.data.message
//       }
//     })
//   } catch (e: any) {
//     useLoadingStore().stop()
//     handleErrors(e)
//   }
// }

// const handleToConfirm = async () => {
//   try {
//     // useLoadingStore().start()
//     const valid = await validate()
//     if (!valid.valid) {
//       useLoadingStore().stop()
//       return
//     }
//     useEntryStore().setParent(parent.value)
//     children.value = []
//     useEntryStore().setChildren([])
//     useLoadingStore().stop()
//     router.push({ name: 'EntryProfileConfirm'})
//   } catch (e: any) {
//     useLoadingStore().stop()
//     handleErrors(e)
//   }
// }


const handleToChild = async () => {
  try {
    useLoadingStore().start()
    const valid = await validate()
    if (!valid.valid) {
      useLoadingStore().stop()
      return
    }
    useEntryStore().setParent(parent.value)
    useLoadingStore().stop()
    router.push({ name: 'EntryProfileChild' })
  } catch (e: any) {
    useLoadingStore().stop()
    handleErrors(e)
  }
}

onMounted(async () => {
  if (!useRoute().query.t || useRoute().query.t === undefined) router.push({ name: 'NotFound' })
  try {
    await API.post('/api/entry/certification', { token: useRoute().query.t?.toString() }).then(response => {
      if (response.data.status == 'success') {
        switch (response.data.message) {
          case 'valid':
            if (useEntryStore().parent) parent.value = useEntryStore().getParent()
            if (useEntryStore().children) children.value = useEntryStore().getChildren()
            parent.value.mailAddress = response.data.mail_address
            mailAddress.value = response.data.mail_address
            break
          case 'expired':
            useEntryStore().clearEntry()
            router.push({ name: 'Expired' })
            break
          default:
            useEntryStore().clearEntry()
            router.push({ name: 'Error', query: { title: '有効なURLではありません', content: '有効なURLではありません' } })
        }
      }
    })
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
})
</script>