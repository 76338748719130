import './bootstrap'
import { createApp } from 'vue'
import App from '../App.vue'
import router from '../router/index'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import '@/bootstrap/css/bootstrap.min.css'
import '../../public/assets/css/common.css'
import '@/css/app.css'
import '@/js/jquery-3.7.1.min.js'
import { createHead } from '@unhead/vue'

const app = createApp(App)

app.use(router)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(createHead())
app.mount('#app')
