<template>
  <div class="form-check form-check-inline" v-for="option in options" :key="option.id">
    <input type="checkbox" class="form-check-input" :id="`check-${props.memberId}-${option.id}`" :value="option" v-model="choice">
    <label class="form-check-label" :for="`check-${props.memberId}-${option.id}`" >{{ option.label }}</label>
  </div>
</template>

<script setup lang="ts">
  import { TChoice, TAnswer } from '@/types/event'
  import { ref, watch } from 'vue'

  const props = defineProps<{
    memberId: number
  }>()

  const options = defineModel<TChoice[]>('options')
  const choices = defineModel<TChoice[]>('choices')
  const answers = defineModel<TAnswer[]>('answers')
  const surveyId = defineModel<number>('surveyId')
  const input = ref<string | null>(null)
  const choice = ref([])
  // watch(() => input.value, (newValue) => {
  //   input.value = newValue
  //   if (answers.value !== undefined) {
  //     const existingIndex = answers.value.findIndex(answer => answer.surveyId === surveyId.value)
  //     if (existingIndex !== -1) {
  //       answers.value.splice(existingIndex, 1, TAnswer.fromData(0, surveyId.value!, props.memberId, null, input.value))
  //     } else {
  //       answers.value.push(TAnswer.fromData(0, surveyId.value!, props.memberId, null, input.value))
  //     }
  //   }
  // })
  
  watch(() => choice.value, (newValue) => {
    choice.value = newValue
    if (choices.value !== undefined && answers.value !== undefined) {
      // const existingIndex = choices.value.findIndex(choice => choice.surveyId === surveyId.value)
      // const existingIndexes = choices.value.reduce((acc: any, choice: TChoice, index: number) => {
      const existingIndexes = answers.value.reduce((acc: any, choice: TAnswer, index: number) => {
        if (choice.surveyId === surveyId.value) {
          acc.push(index)
        }
        return acc
      }, [])
      if (existingIndexes) {
        const newValueLength = newValue.length;
        const existingIndexesLength = existingIndexes.length;
        const minLength = Math.min(newValueLength, existingIndexesLength);

        for (let i = 0; i < minLength; i++) {
          const existingIndex: number = existingIndexes[i];
          const newValueItem: TChoice = newValue[i];

          choices.value.splice(existingIndex, 1, newValueItem);
          if (surveyId.value) {
            answers.value.splice(existingIndex, 1, TAnswer.fromData(0, surveyId.value, props.memberId, newValueItem.id, null))
          }
        }

        if (newValueLength > existingIndexesLength) {
          const remainingItems: TChoice[] = newValue.slice(existingIndexesLength);
          choices.value.push(...remainingItems)
          if (surveyId.value) {
            answers.value.push(...remainingItems.map(item => TAnswer.fromData(0, item.surveyId, props.memberId, item.id, null)))
          }
        } else if (newValueLength < existingIndexesLength) {
          const remainingIndexes = existingIndexes.slice(newValueLength);
          remainingIndexes.reverse().forEach(index => {
            if (choices.value && answers.value !== undefined) {
              choices.value.splice(index, 1)
              answers.value.splice(index, 1)
            }
          })
        }
      } else {
        newValue.forEach((v: TChoice )=> {
          if (choices.value && answers.value && surveyId.value) {
            choices.value.push(v)
            answers.value.push(TAnswer.fromData(0, surveyId.value, props.memberId, v.id, null))
          }
        })
      }
    }
  })
</script>