import { TMember } from '@/types/member'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useEntryStore = defineStore('entryState', () => {
  
  const parent = ref<TMember>(TMember.createEmptyMember())
  const children = ref<TMember[]>([])

  function setParent (parentArg: TMember): void {
    parent.value = parentArg
  }

  function getParent (): TMember {
    return parent.value
  }

  function setChildren (childrenArg: TMember[]): void {
    children.value = childrenArg
  }

  function getChildren (): TMember[] {
    return children.value
  }

  function clearEntry() {
    parent.value = TMember.createEmptyMember()
    children.value = []
  }

  return {
    parent,
    setParent,
    getParent,
    children,
    setChildren,
    getChildren,
    clearEntry,
  }
},
// {
//   persist: true,
// }
)
