<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">プログラム予約申込完了</li>
    </ol>
  </nav>
  <div class="container">
    <div class="top--section">
      <h1>プログラム予約申込完了</h1>
      <p>
        プログラム予約申込が完了しました。<br>
      </p>
      <router-link to="/top" class="btn btn-primary">トップページへ戻る</router-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useLoadingStore } from '@/stores/loading';

useLoadingStore().stop()
</script>