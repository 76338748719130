import axios, { AxiosRequestConfig } from 'axios'
import { InValidServiceException, UnAuthorizedException, ConfirmServiceException, TokenMismatchException, InternalServerException, UnprocessableException } from './config/exception' // eslint-disable-line no-unused-vars
import { useMemberStore } from './stores/member'

export const API = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    // 'X-XSRF-TOKEN': useMemberStore().xsrfToken
  }
})

// API.interceptors.request.use((config: AxiosRequestConfig<any>) => {
//   config.headers = {
//     // TODO: ヘッダーにx-xsrf-tokenを詰めて通信を行いたい
//     'X-XSRF-TOKEN': useMemberStore().xsrfToken,
//   }
//   return config
// })

API.interceptors.response.use((response) => response, async (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      const { message, data } = error.response.data
      throw new UnAuthorizedException(data, message)
    }
    if (error.response.status === 409) {
      const { message } = error.response.data
      throw new ConfirmServiceException(message)
    }
    if (error.response.status === 400) {
      const { message, data } = error.response.data
      throw new InValidServiceException(data, message)
    }
    if (error.response.status === 419) {
      throw new TokenMismatchException()
    }
    if (error.response.status === 500) {
      let { message, data } = error.response.data
      throw new InternalServerException(data, message)
    }
    if (error.response.status === 422) {
      const { message, data } = error.response.data
      throw new UnprocessableException(data, message)
    }
  }
  throw new Error('unknown')
})
