<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">お問い合せ送信完了</li>
    </ol>
  </nav>
  <div class="container">
    <div class="top--section">
      <h2>お問い合せ送信完了</h2>
      <br />
      お問い合せを送信しました。<br />
      <br />
      <router-link to="/" class="btn btn-primary">トップページへ戻る</router-link>
    </div>
  </div>
</template>
