<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/search/index">プログラム検索</router-link></li>
      <li class="breadcrumb-item"><router-link v-if="event"
          :to="{ name: 'EventDetail', params: { path: event.path } }">プログラム詳細</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">キャンセル待ちプログラム予約申込</li>
    </ol>
  </nav>
  <div class="container">
    <h2>キャンセル待ちプログラム予約申込</h2>
    <div class="entry-form-inner">
      <p class="mt-3">プログラム名、料金をご確認の上、確認ボタンをクリックしてください。</p>
      <div class="table-wrap">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03 tblTit">プログラムコード</th>
              <td>{{ event?.id }}</td>
            </tr>
            <tr>
              <th class="tblBg03">プログラム名</th>
              <td>{{ event?.title }}</td>
            </tr>
            <tr>
              <th class="tblBg03">開催日</th>
              <td>
                <template v-for="schedule in eventSchedules" :key="schedule.id">
                  <div class="row m-0">
                    <div class="form-check">
                      <template v-if="schedule.startAt && schedule.endAt && schedule.startAt === schedule.endAt">
                        <input type="checkbox" class="form-check-input" :id="`schedule-${schedule.id}`"
                        v-model="schedule.isChecked">
                        <label class="form-check-label" :for="`schedule-${schedule.id}`">{{ toDateSlash(schedule.startAt) }} </label>
                      </template>
                      <template v-else-if="schedule.startAt && schedule.endAt">
                        <input type="checkbox" class="form-check-input" :id="`schedule-${schedule.id}`"
                        v-model="schedule.isChecked">
                        <label class="form-check-label" :for="`schedule-${schedule.id}`" >{{ toDateSlash(schedule.startAt) }}{{ schedule.endAt ? '〜' + toDateSlash(schedule.endAt) : '' }}  </label>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- <div class="row m-0">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="class-start_20240115">
                    <label class="form-check-label" for="class-start_20240115">2024/01/14(キャンセル待ち)</label>
                  </div>
                </div>
                <div class="row row-cols-1 row-cols-sm-2 m-0">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="class-start_20240115">
                    <label class="form-check-label" for="class-start_20240115">2024/01/15</label>
                  </div>
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="class-start_20240122">
                  <label class="form-check-label" for="class-start_20240122">2024/01/22〜25</label>
                </div> -->
              </td>
            </tr>
            <tr>
              <th class="tblBg03">支払い方法</th>
              <td>
                <select name="select-payment" class="form-control form-select" v-model="paymentId">
                  <option v-for="pay in paymentMethods" :key="pay.id" :value="pay.id">{{ pay.name }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th class="tblBg03">基本費用</th>
              <td>{{ event?.priceDetail }}</td>
            </tr>
            <tr>
              <th class="tblBg03">スキー教室参加に伴い、同意書に捺印して頂く必要があります。</th>
              <td>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="doui" v-model="isAgree">
                  <label for="doui" class="form-check-label">
                    了承しました
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-wrap">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03">参加者選択</th>
              <td>
                <div class="row row-cols-1 row-cols-sm-2 m-0">
                  <template v-for="rental in rentals" :key="rental.member.id">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" :id="`child-${rental.member.id}`" :value="true"
                        v-model="rental.member.willAttend">
                      <label class="form-check-label" :for="`child-${rental.member.id}`">{{ rental.member.firstName
                        }}</label>
                    </div>
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="table-wrap option-color" v-for="child in children" :key="child.id"> -->
      <template v-for="rental in rentals" :key="rental.id">
        <div class="table-wrap option-color" v-if="rental.member.willAttend">
          <table class="normal mgT20" cellspacing="0">
            <tbody>
              <tr>
                <th colspan="2" class="tblBg03">{{ rental.member.firstName }}さんの確認 / オプション</th>
              </tr>
              <template v-for="survey in surveys" :key="survey.id">
                <tr v-if="survey.type">
                  <th class="tblBg03">{{ survey.content }}</th>
                  <td>
                    <div v-if="survey.type === 1">
                      <select-component v-model:options="survey.choices" v-model:survey-id="survey.id"
                        v-model:choices="rental.choices" v-model:answers="rental.answers"
                        :memberId="rental.member.id"></select-component>
                    </div>
                    <div v-if="survey.type === 2">
                      <radio-component v-model:options="survey.choices" v-model:survey-id="survey.id"
                        v-model:choices="rental.choices" v-model:answers="rental.answers"
                        :memberId="rental.member.id"></radio-component>
                    </div>
                    <div v-if="survey.type === 3">
                      <text-component v-model:options="survey.choices" v-model:survey-id="survey.id"
                        v-model:choices="rental.choices" v-model:answers="rental.answers"
                        :memberId="rental.member.id"></text-component>
                    </div>
                  </td>
                </tr>
              </template>
              <!-- <tr>
                <th class="tblBg03">レンタルウェアの希望</th>
                <td>
                  <select name="option3" class="form-control form-select">
                    <option value="▼をクリックしてお選びください">▼をクリックしてお選びください</option>
                    <option value="レンタルを希望しない">レンタルを希望しない</option>
                    <option value="レンタルウェアを希望（+６，８２０円）">レンタルウェアを希望（+６，８２０円）</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th class="tblBg03">レンタルヘルメット</th>
                <td>
                  <select name="option4" class="form-control form-select">
                    <option value="▼をクリックしてお選びください">▼をクリックしてお選びください</option>
                    <option value="レンタルを希望しない">レンタルを希望しない</option>
                    <option value="レンタルヘルメットを希望（+１，６５０円）">レンタルヘルメットを希望（+１，６５０円）</option>
                  </select>
                </td>
              </tr> -->
              <tr>
                <th class="tblBg03">{{ rental.member.firstName }}レンタル費用小計</th>
                <td class="d-flex justify-content-end fw-bold"><span class="subtotal">¥ {{ rental.getTotalChoicesValue()
                    }}</span>
                </td>
                <!-- <td class="d-flex justify-content-end fw-bold"><span class="subtotal">¥ {{ calcTotal(choices) }}</span></td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <div class="table-wrap total-color">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03">費用合計</th>
              <td class="d-flex justify-content-end fw-bold"><span class="total">¥ {{ calcTotal }}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn-area">
        <button class="btn btn-back" @click="$router.go(-1)">検索結果へ戻る</button>
        <button type="button" class="btn btn-apply" @click="toConfirm()" :disabled="!isAgree">確認</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { RouteParamsRaw, useRoute, useRouter } from 'vue-router'
import { TEvent, TEventMembership, TEventTarget, TEventSchedule, TPaymentMethod, TSurvey, TChoice, TAnswer, TRental } from '@/types/event'
import { useMemberStore } from '@/stores/member'
import { useEventStore } from '@/stores/event'
import { TMember } from '@/types/member'
import { toDateSlash } from '@/js/util'
import SelectComponent from '@/components/Surveys/SelectComponent.vue'
import RadioComponent from '@/components/Surveys/RadioComponent.vue'
import TextComponent from '@/components/Surveys/TextComponent.vue'
import { handleErrors } from '@/config/handleErrors'

const route = useRoute()
const router = useRouter()
const memberStore = useMemberStore()

const event = ref<TEvent>()
const eventTargets = ref<TEventTarget[]>()
const eventMemberships = ref<TEventMembership[]>()
const minTarget = ref<TEventTarget>()
const maxTarget = ref<TEventTarget>()
const eventSchedules = ref<TEventSchedule[]>()
const paymentMethods = ref<TPaymentMethod[]>()
const parent = ref<TMember>()
const children = ref<TMember[]>()
const surveys = ref<TSurvey[]>()

const isAgree = ref<boolean>(false)
const paymentId = ref<number>(0)
const choices = ref<TChoice[]>([])
const answers = ref<TAnswer[]>([])

const rentals = ref<TRental[]>([])

onMounted(async () => {
  try {
    const { event: fetchEvent,
      parent: fetchParent,
      children: fetchChildren,
      schedules: fetchSchedules,
      paymentMethods: fetchPayments,
      surveys: fetchSurvey,
    }
      = await TEvent.fetchEventMember({ id: Number(route.query.id), memberId: memberStore.id })
    event.value = fetchEvent
    parent.value = fetchParent
    children.value = fetchChildren
    eventSchedules.value = fetchSchedules
    paymentMethods.value = fetchPayments
    surveys.value = fetchSurvey
    for (const child of children.value) {
      rentals.value.push(TRental.fromData(0, child.id, child, [], []))
    }
  } catch (e: any) {
    handleErrors(e)
  }
})

const calcTotal = computed(() => {
  let total = 0
  for (const rental of rentals.value) {
    total += rental.getTotalChoicesValue()
  }
  return total
})

const toConfirm = () => {
  const data = {
    eventArg: event.value,
    parentArg: parent.value,
    childrenArg: children.value,
    schedulesArg: eventSchedules.value,
    paymentMethodsArg: paymentMethods.value,
    surveysArg: surveys.value,
    rentalsArg: rentals.value,
    paymentIdArg: paymentId.value
  }
  // useEventStore().setEventConfirm(event,
  //   parent,
  //   children,
  //   eventSchedules,
  //   paymentMethods,
  //   surveys,
  //   rentals)
  useEventStore().setEventConfirm(data)
  router.push({ name: 'EventConfirm' })
}

</script>