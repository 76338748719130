<template>
	<nav aria-label="breadcrumb" class="breadcrumb-area">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
		<li class="breadcrumb-item active" aria-current="page">パスワード再設定</li>
	</ol>
	</nav>
  	<div class="container">
  		<h1>パスワード再設定完了</h1>
		<div class="top--section">
  			<p>
				パスワードを再設定しました。ホームよりログインをお願いいたします。
			</p>
			<router-link to="/" class="btn btn-primary">トップページへ戻る</router-link>
		</div>
	</div>
</template>