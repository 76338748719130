<template>
    <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">メンバー仮登録完了</li>
    </ol>
    </nav>
    <div class="container">
        <h1>メンバー仮登録完了</h1>
        <div class="top--section">
            <p class="mb-5">
                ご登録いただいたメールアドレスに認証メールを送信しました。<br />
                メールに記載されているURLを1時間以内にクリックし、新規登録を完了させてください。  		
            </p>
            <router-link to="/" class="btn btn-primary">トップページへ戻る</router-link>
        </div>
    </div>
</template>
