<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">お知らせ詳細</li>
    </ol>
  </nav>
  <div class="container">
    <div class="top--section">
      <h1 v-if="notice">{{ notice.title }}</h1>
      <p v-if="notice" v-html="notice.content.replace(/\n/g, '<br>')">
      </p>
      <router-link :to="{ path: '/top'}" class="btn btn-primary">トップページへ戻る</router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { TNotice } from '@/types/top'
import { useLoadingStore } from '@/stores/loading'
import { defineProps } from 'vue';
import { handleErrors } from '@/config/handleErrors'

const props = defineProps<{
  path: string;
}>();

const notice = ref<TNotice>()
useLoadingStore().start()

onMounted(async () => {
  try {
    const path = props.path
    if (path) {
      const {
        notice: news
      } = await TNotice.fetchNoticeDetail({ path: path })
      notice.value = news
    }  
  } catch(e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
})
</script>
