<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">メンバー登録完了</li>
    </ol>
  </nav>
  <div class="container">
    <div class="top--section">
      <h1>メンバー登録完了</h1>
      <p>
				メンバー登録が完了しました。<br />
			</p>
			<router-link to="/" class="btn btn-primary">トップページへ戻る</router-link>
    </div>
  </div>
</template>
