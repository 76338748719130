<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">ログイン</li>
    </ol>
  </nav>
  <div class="container">
    <!-- <loading v-model:active="isLoading" :is-full-page="fullPage"/> -->
    <h1>ログイン</h1>
    <div class="entry-form-inner">
      <form>
        <div class="form-login">
          <div class="mb-3 row">
            <label for="inputEmail" class="col-sm-4 col-form-label">メールアドレス&nbsp<span
                class="badge bg-danger">必須</span></label>
            <div class="col-sm-8">
              <Field name="mailAddress" type="email" class="form-control" id="floatingInput"
                placeholder="name@example.com" />
              <div class="text-danger form-error">{{ errors.mailAddress }}</div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-4 col-form-label">パスワード&nbsp<span
                class="badge bg-danger">必須</span></label>
            <div class="col-sm-8">
              <div class="login-pass-eyes">
                <Field name="password" type="password" class="form-control" id="floatingPassword" />
                <i class="bi bi-eye-slash" v-if="eye" @click="changeEye"></i>
                <i class="bi bi-eye" v-else @click="changeEye"></i>
              </div>

              <div class="text-danger form-error">{{ errors.password }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-8">
              <div class="form-check text-start">
                <Field name="remember" class="form-check-input" type="checkbox" :value="1" id="flexCheckDefault" />
                <label class="form-check-label" for="flexCheckDefault">
                  ログイン情報を記憶する
                </label>
              </div>
            </div>
            <div class="row justify-content-center text-danger form-error">{{ loginError }}</div>
          </div>

          <div class="btn-area">
            <button class="btn btn-apply" type="button" :disabled="!canLogin()" @click="login">ログイン</button>
          </div>
          <div class="row justify-content-center">
            <p class="text-center">パスワード忘れは<router-link to="/password/index">こちら</router-link>から</p>
            <hr>
            <p class="text-center">メンバー登録がまだの方</p>
            <router-link to="/entry/index" class="btn btn-primary">新規メンバー登録</router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang='ts'>
import { useRouter } from 'vue-router'
import { configure, useField, useForm, Field } from 'vee-validate'
import { ref } from 'vue'
import * as yup from 'yup'
import { useMemberStore } from '@/stores/member'
import { API } from '../../api'
import { useLoadingStore } from '@/stores/loading'
import { TMember } from '@/types/member'
import { handleErrors } from '@/config/handleErrors'

const router = useRouter()
const memberStore = useMemberStore()
const loginError = ref('')
const eye = ref(true)

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
})

const schema = yup.object({
  mailAddress: yup.string().required('メールアドレスは必須項目です').email('メールアドレスの形式で入力してください'),
  password: yup.string().required('パスワードは必須項目です'),
})
const { validate, errors, values } = useForm({ validationSchema: schema })
const { value: mailAddress } = useField<string>('mailAddress')
const { value: password } = useField<string>('password')
const { value: remember } = useField<number>('remember')

const canLogin = () => {
  if (mailAddress.value && password.value) return true
}

const changeEye = () => {
  const passwordField = document.getElementById('floatingPassword') as HTMLInputElement
      if(eye.value){
        eye.value = false
        passwordField.type = 'text'
      }else{
        eye.value = true
        passwordField.type = 'password'
      }
}

const login = async () => {
  const valid = await validate()
  if (!valid.valid) return
  useLoadingStore().start()
  // API.get('/sanctum/csrf-cookie').then((res) => {
  //   if (res.config.headers) {
  //     useMemberStore().setXsrfToken(res.config.headers['X-XSRF-TOKEN'] as string)
  //   }
  try{
    API.post('/api/login/index', {
      mail_address: mailAddress.value,
      password: password.value,
      remember: remember.value
    }).then(response => {
      if (response.data.status == "success") {
        const member = response.data.member
        const memberships = member.member_membership.map(m => m.membership_type_id)
        memberStore.authenticate(response.data.token, member.id, (member.last_name + ' ' + member.first_name), member.mail_address, memberships)
        router.push('/mypage/index')
      } else {
        useLoadingStore().stop()
        // loginError.value = 'メールアドレスもしくはパスワードが間違っています'
        loginError.value = response.data.message
      }
    }).catch(error => {
      useLoadingStore().stop()
      // const errKey = Object.keys(error.response.data.errors)[0]
      // console.error(error.response.data.errors[errKey][0])
      console.error(error)
      handleErrors(error)
    })
  // })
  } catch (e: any) {
    handleErrors(e)
  }
}
</script>