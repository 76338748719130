<template>
  <div>
    <loading v-model:active="loadingStore.isLoading" :is-full-page="loadingStore.fullPage" loader="bars"/>
    <template v-if="memberStore.isLoggedIn">
      <LoginHeaderBar/>
    </template>
    <template v-else>
      <HeaderBar/>
    </template>
    <main>
      <router-view></router-view>
    </main>
    <FooterBar/>
  </div>
</template>

<script setup lang="ts">
import LoginHeaderBar from '@/components/Layouts/LoginHeaderBar.vue'
import HeaderBar from '@/components/Layouts/HeaderBar.vue'
import FooterBar from '@/components/Layouts/FooterBar.vue'
import { useMemberStore } from './stores/member'
import { useLoadingStore } from './stores/loading'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

const memberStore = useMemberStore()

const loadingStore = useLoadingStore()

</script>