
export class InValidServiceException extends Error {
  data: {}
  message: string
  constructor (data: {}, message = 'InValidServiceException') {
    super(message)
    this.message = message || ''
    this.data = data
    this.name = 'InValidServiceException'
  }
}

export class UnAuthorizedException extends Error {
  data: {}
  message: string
  constructor (data: {}, message = 'UnAuthorizedException') {
    super(message)
    this.message = message || ''
    this.data = data
    this.name = 'UnAuthorizedException'
  }

  static plsLogin () {
    window.location.reload()
  }
}

export class ConfirmServiceException extends Error {
  message: string
  constructor (message: string) {
    super('ConfirmServiceException')
    this.message = message
    this.name = 'ConfirmServiceException'
  }
}

export class TokenMismatchException extends Error {
  constructor (message = 'TokenMismatchException') {
    super(message)
    this.name = 'TokenMismatchException'
  }

  static plsLogin () {
    window.location.reload()
  }
}

export class InternalServerException extends Error {
  data: {}
  message: string
  constructor (data: {}, message = 'InternalServerException') {
    super(message)
    this.message = message || ''
    this.data = data
    this.name = 'InternalServerException'
  }
}

export class UnprocessableException extends Error {
  data: {}
  message: string
  constructor (data: {}, message = 'UnprocessableException') {
    super(message)
    this.message = message || ''
    this.data = data
    this.name = 'UnprocessableException'
  }
}

export default {}
