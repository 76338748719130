<template>

</template>

<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { API } from '../../api'
// import { setActivePinia } from 'pinia'
import { handleError, onMounted, ref } from 'vue'
import { stringToDateSlash } from '@/js/util'
import { useMemberStore } from '@/stores/member';
import { handleErrors } from '@/config/handleErrors';
import { useLoadingStore } from '@/stores/loading'
import { toDateSlash } from '@/js/util'
import { TEvent, TEventMembership, TEventSchedule, TEventTarget } from '@/types/event'
import { routeLocationKey, useRoute, useRouter } from 'vue-router';
import router from '@/router';
useLoadingStore().start()

onMounted(() => {
  try {
    API.post('/api/login/impersonate', useRoute().query ).then((r) => {
      if (r.data.status === 'success') {
        const member = r.data.member
        const memberships = member.member_membership.map(m => m.membership_type_id)
        useMemberStore().authenticate(null, member.id, (member.last_name + ' ' + member.first_name), member.mail_address, memberships)
        router.push('/mypage/index')
      } else {
        router.push({ name: 'Top'})
      }
    }).catch((error) => {
      handleErrors(error)
    })
  } catch (e: any) {
    handleErrors(e)
    router.push({ name: 'Top'})
  } finally {
    useLoadingStore().stop()
  }
})

</script>
