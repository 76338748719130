<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/mypage/index">マイページ</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">退会確認</li>
    </ol>
  </nav>
  <div class="container-fluid">
    <div class="row mypage-contents">
      <side-bar />
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4" v-if="isWithdrawal">
        <h2>退会確認</h2>
        <div class="entry-form-inner">
          <h4>本当に退会しますか？</h4>
          <div class="btn-area">
            <button class="btn btn-back" @click="$router.go(-1)">戻る</button>
            <button class="btn btn-primary" @click="withdrawal">退会する</button>
          </div>
        </div>
      </div>
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4" v-else>
        <h2>退会確認</h2>
        <div class="entry-form-inner">
          <h4>参加予定のプログラムがあるため退会できません。<br>
            お申し込み先YMCAにご連絡し、プログラムキャンセルの手続きをお願いいたします。
          </h4>
          <div class="btn-area">
            <button class="btn btn-back" @click="$router.go(-1)">戻る</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useMemberStore } from '@/stores/member'
import { API } from '../../api'
import { handleErrors } from '@/config/handleErrors';
import { useLoadingStore } from '@/stores/loading'


const memberStore = useMemberStore()
const router = useRouter()
const isWithdrawal = ref(false)

onMounted(() => {
  useLoadingStore().start()
  try {
    API.get('/api/mypage/withdrawal',{ params:{id :useMemberStore().$state.id}}).then((r) => {
      if(r.data.status == "success")
      isWithdrawal.value = true
      useLoadingStore().stop()
    }).catch((error) => {
      useLoadingStore().stop()
      handleErrors(error)
    })
  } catch (e: any) {
    handleErrors(e)
  }
})

const withdrawal = () => {
  useLoadingStore().start()
  API.delete('/api/withdrawal', {
    params: {
      id: useMemberStore().$state.id,
      address: useMemberStore().$state.mailAddress
    }
  }).then(response => {
    useLoadingStore().stop()
    if (response.data.status == "success") {
      useMemberStore().logout()
      router.push('/');
    } else {
    }
  })
    .catch(error => {
      useLoadingStore().stop()
      const errKey = Object.keys(error.response.data.errors)[0]
      console.error(error.response.data.errors[errKey][0])
      handleErrors(error)
    })
}


</script>
