import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useMemberStore = defineStore('memberState', () => {
  
  const id = ref(0)
  const name = ref('')
  const mailAddress = ref('')
  const memberships = ref<number[]>([])
  const token = ref('')
  const xsrfToken = ref('')
  const isLoggedIn = computed(() => id.value? true : false)

  function setMember (memberId: number, memberName: string, address: string, ships: number[]): void {
    id.value = memberId
    name.value = memberName
    mailAddress.value = address
    memberships.value = ships
  }

  function authenticate(authToken, id?, name?, mailAddress?, memberships?): void {
    token.value = authToken;
    setMember(id, name, mailAddress, memberships)
    localStorage.setItem('token', token.value);
  }

  function logout(): void {
    id.value = 0
    name.value = ''
    mailAddress.value = ''
    memberships.value = []
    token.value = ''
    xsrfToken.value = ''
    localStorage.removeItem('token')
  }

  function setXsrfToken(token) {
    xsrfToken.value = token
  }

  return {
    id,
    name,
    mailAddress,
    memberships,
    xsrfToken,
    isLoggedIn,
    setMember,
    authenticate,
    logout,
    setXsrfToken
  }
},
{
  persist: true,
})