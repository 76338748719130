import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import { TEvent, TEventMembership, TEventTarget, TEventSchedule, TPaymentMethod, TSurvey, TChoice, TAnswer, TRental } from '@/types/event'
import { TMember } from '@/types/member'

export const useEventStore = defineStore('eventState', () => {
  
  const event = ref<TEvent>()
  const schedules = ref<TEventSchedule[]>()
  const paymentMethods = ref<TPaymentMethod[]>()
  const parent = ref<TMember>()
  const children = ref<TMember[]>()
  const surveys = ref<TSurvey[]>()
  const rentals = ref<TRental[]>()
  const paymentId = ref<number>(0)

  function setEventConfirm (
    {
      eventArg,
      parentArg,
      childrenArg,
      schedulesArg,
      paymentMethodsArg,
      surveysArg,
      rentalsArg,
      paymentIdArg,
    }: {
      eventArg: TEvent | undefined,
      parentArg: TMember | undefined,
      childrenArg: TMember[] | undefined, 
      schedulesArg: TEventSchedule[] | undefined, 
      paymentMethodsArg: TPaymentMethod[] | undefined,
      surveysArg: TSurvey[] | undefined,
      rentalsArg: TRental[] | undefined,
      paymentIdArg: number
    }): void {
    event.value = eventArg
    parent.value = parentArg
    children.value = childrenArg
    schedules.value = schedulesArg
    paymentMethods.value = paymentMethodsArg
    surveys.value = surveysArg
    rentals.value = rentalsArg
    paymentId.value = paymentIdArg
  }

  function getEventConfirm(): {
    event: TEvent | undefined,
    parent: TMember | undefined,
    children: TMember[] | undefined, 
    schedules: TEventSchedule[] | undefined, 
    paymentMethods: TPaymentMethod[] | undefined,
    surveys: TSurvey[] | undefined,
    rentals: TRental[] | undefined,
    paymentId: number
  }
    {
    return {
      event: event.value,
      parent: parent.value,
      children: children.value,
      schedules: schedules.value,
      paymentMethods: paymentMethods.value,
      surveys: surveys.value,
      rentals: rentals.value,
      paymentId: paymentId.value
    }
  }

  function clearEvent() {
    event.value = undefined
    parent.value = undefined
    children.value = undefined
    schedules.value = undefined
    paymentMethods.value = undefined
    surveys.value = undefined
    rentals.value = undefined
    paymentId.value = 0
  }

  return {
    event,
    parent,
    children,
    schedules,
    paymentMethods,
    surveys,
    rentals,
    setEventConfirm,
    getEventConfirm,
    clearEvent
  }
},
{
  persist: true,
})