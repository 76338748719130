<template>
  <div>
    <nav aria-label="breadcrumb" class="breadcrumb-area">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
        <li class="breadcrumb-item"><router-link to="/mypage/index">マイページ</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">プロフィール変更</li>
      </ol>
    </nav>
    <div class="container-fluid">
      <div class="row mypage-contents">
        <side-bar />
        <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4">
          <h1>プロフィール変更</h1>
          <div class="top--section">
            <div class="mb-3 row">
              <label for="inputEmail" class="col-sm-3 col-form-label">メールアドレス&nbsp;<span
                  class="badge bg-danger">必須</span></label>
              <div class="col-sm-6">
                <p class="form-control-static">{{ mailAddress }}</p>
              </div>
            </div>
            <!-- <div class="mb-3 row">
						<label for="inputPassword" class="col-sm-3 col-form-label">パスワード&nbsp;<span class="badge bg-danger">必須</span></label>
						<div class="col-sm-6">
							<input type="password" name="password" class="form-control" id="inputPassword">
							<div class="text-danger form-error">エラー表示エラー表示エラー表示</div>
						</div>
					</div> -->
            <div class="mb-3 row">
              <label for="inputSei" class="col-sm-3 col-form-label">氏名&nbsp;<span
                  class="badge bg-danger">必須</span></label>
              <div class="row col-sm-6">
                <div class="col-sm-6 pe-0">
                  <Field type="text" name="lastName" placeholder="姓" class="form-control" id="inputSei" v-if="parent"
                    v-model="parent.lastName" maxlength="16" />
                </div>
                <div class="col-sm-6 pe-0">
                  <Field type="text" name="firstName" placeholder="名" class="form-control" id="inputMei" v-if="parent"
                    v-model="parent.firstName" maxlength="16" />
                </div>
                <div class="col-12 text-danger form-error" v-if="parent">{{ errors.lastName || errors.firstName }}</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="inputSeiKana" class="col-sm-3 col-form-label">氏名(フリガナ)&nbsp;<span
                  class="badge bg-danger">必須</span></label>
              <div class="row col-sm-6">
                <div class="col-sm-6 pe-0">
                  <Field type="text" name="lastNameKana" placeholder="セイ" class="form-control" id="inputSeiKana" v-if="parent"
                    v-model="parent.lastNameKana" maxlength="16" />
                </div>
                <div class="col-sm-6 pe-0">
                  <Field type="text" name="firstNameKana" placeholder="メイ" class="form-control" id="inputMeiKana" v-if="parent"
                    v-model="parent.firstNameKana" maxlength="16" />
                </div>
                <div class="col-12 text-danger form-error">{{ errors.lastNameKana || errors.firstNameKana }}</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="inputBirthday" class="col-sm-3 col-form-label">生年月日 &nbsp;<span
                  class="badge bg-danger">必須</span></label>
              <div class="col-sm-9 d-flex" v-if="parent">
                <div class="col-sm-3">
                  <div class="input-group">
                    <Field as="select" name="year" id="inputBirthday" class="form-control form-select"
                      v-model="parent.year">
                      <option value=""></option>
                      <option v-for="y in 100" :key="y" :value="((thisYear + 1) - y).toString()">{{ (thisYear + 1) - y
                        }}</option>
                    </Field>
                    <div class="input-group-prepend">
                      <div class="input-group-text">年</div>
                    </div>
                    <div class="col-12 text-danger form-error" v-if="parent">{{ errors.year }}</div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <Field as="select" name="month" class="form-control form-select" v-model="parent.month">
                      <option value=""></option>
                      <option v-for="m in 12" :key="m" :value="m.toString()">{{ m }}</option>
                    </Field>
                    <div class="input-group-prepend">
                      <div class="input-group-text">月</div>
                    </div>                    
                    <div class="col-12 text-danger form-error" v-if="parent">{{ errors.month }}</div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <Field as="select" name="day" class="form-control form-select" v-model="parent.day">
                      <option value=""></option>
                      <option v-for="d in lastDay" :key="d" :value="d.toString()">{{ d }}</option>
                    </Field>
                    <div class="input-group-prepend">
                      <div class="input-group-text">日</div>
                    </div>
                    <div class="col-12 text-danger form-error" v-if="parent">{{ errors.day }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="inputSex-chil" class="col-sm-3 col-form-label">性別&nbsp;<span
              class="badge bg-danger">必須</span></label>
              <div class="col-sm-8 mt-2" >
                <div class="form-check form-check-inline">
                  <Field type="radio" name="gender" :value=1 class="form-check-input" v-model="parent.gender">
                  </Field>
                  <label class="form-check-label" :for="`gender-man`">男性</label>
                </div>
                <div class="form-check form-check-inline">
                  <Field type="radio" name="gender" :value=2 class="form-check-input" v-model="parent.gender">
                  </Field>
                  <label class="form-check-label" :for="`gender-woman`">女性</label>
                </div>
                <div class="col-12 text-danger form-error">{{ errors[`gender`] }}</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="phoneNumber" class="col-sm-3 col-form-label">電話番号&nbsp;<span
                  class="badge bg-danger">必須</span></label>
              <div class="col-sm-6">
                <Field type="text" name="phoneNumber" class="form-control" id="inputTel" placeholder="" v-if="parent"
                  maxlength="13" v-model="parent.phoneNumber">
                </Field>
                <div class="col-12 text-danger form-error" v-if="parent">{{ errors.phoneNumber }}</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="inputZipcode" class="col-sm-3 col-form-label">郵便番号<span
                  class="badge bg-danger">必須</span></label>
              <div class="col-sm-3">
                <Field type="text" name="postalCode" class="form-control" id="inputZipcode" placeholder="" v-if="parent" @change="getAddress"
                  v-model="parent.postalCode" maxlength="7">
                </Field>
                <div class="col-12 text-danger form-error" v-if="parent">{{ errors.postalCode }}</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="inputPrefecture" class="col-sm-3 col-form-label">都道府県&nbsp;<span
                  class="badge bg-danger">必須</span></label>
              <div class="col-sm-3">
                <Field as="select" name="prefecture" class="form-control" id="inputPrefecture" v-if="parent"
                  v-model="parent.prefecture">
                  <option value=""></option>
                  <option value="北海道">北海道</option>
                  <option value="青森県">青森県</option>
                  <option value="岩手県">岩手県</option>
                  <option value="宮城県">宮城県</option>
                  <option value="秋田県">秋田県</option>
                  <option value="山形県">山形県</option>
                  <option value="福島県">福島県</option>
                  <option value="茨城県">茨城県</option>
                  <option value="栃木県">栃木県</option>
                  <option value="群馬県">群馬県</option>
                  <option value="埼玉県">埼玉県</option>
                  <option value="千葉県">千葉県</option>
                  <option value="東京都">東京都</option>
                  <option value="神奈川県">神奈川県</option>
                  <option value="新潟県">新潟県</option>
                  <option value="富山県">富山県</option>
                  <option value="石川県">石川県</option>
                  <option value="福井県">福井県</option>
                  <option value="山梨県">山梨県</option>
                  <option value="長野県">長野県</option>
                  <option value="岐阜県">岐阜県</option>
                  <option value="静岡県">静岡県</option>
                  <option value="愛知県">愛知県</option>
                  <option value="三重県">三重県</option>
                  <option value="滋賀県">滋賀県</option>
                  <option value="京都府">京都府</option>
                  <option value="大阪府">大阪府</option>
                  <option value="兵庫県">兵庫県</option>
                  <option value="奈良県">奈良県</option>
                  <option value="和歌山県">和歌山県</option>
                  <option value="鳥取県">鳥取県</option>
                  <option value="島根県">島根県</option>
                  <option value="岡山県">岡山県</option>
                  <option value="広島県">広島県</option>
                  <option value="山口県">山口県</option>
                  <option value="徳島県">徳島県</option>
                  <option value="香川県">香川県</option>
                  <option value="愛媛県">愛媛県</option>
                  <option value="高知県">高知県</option>
                  <option value="福岡県">福岡県</option>
                  <option value="佐賀県">佐賀県</option>
                  <option value="長崎県">長崎県</option>
                  <option value="熊本県">熊本県</option>
                  <option value="大分県">大分県</option>
                  <option value="宮崎県">宮崎県</option>
                  <option value="鹿児島県">鹿児島県</option>
                  <option value="沖縄県">沖縄県</option>
                </Field>
                <div class="col-12 text-danger form-error" v-if="parent">{{ errors.prefecture }}</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="inputCity" class="col-sm-3 col-form-label">市区町村&nbsp;<span
                  class="badge bg-danger">必須</span></label>
              <div class="col-sm-6">
                <Field type="text" name="city" class="form-control" id="inputCity" placeholder="" v-if="parent"
                  v-model="parent.city">
                </Field>
                <!-- <input type="text" name="city" class="form-control" id="inputCity" placeholder=""> -->
                <div class="col-12 text-danger form-error" v-if="parent">{{ errors.city }}</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="inputAddress" class="col-sm-3 col-form-label">番地&nbsp;<span
                  class="badge bg-danger">必須</span></label>
              <div class="col-sm-6">
                <Field type="text" name="streetAddress" class="form-control" id="inputAddress" placeholder="" v-if="parent"
                  v-model="parent.streetAddress">
                </Field>
              <div class="col-12 text-danger form-error" v-if="parent">{{ errors.streetAddress }}</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="inputBuilding" class="col-sm-3 col-form-label">建物名</label>
              <div class="col-sm-6">
                <Field type="text" name="buildingName" class="form-control" id="inputBuilding" placeholder="" v-if="parent"
                  v-model="parent.buildingName">
                </Field>
              <div class="col-12 text-danger form-error" v-if="parent">{{ errors.buildingName }}</div>
              </div>
            </div>
            <!-- <div class="mb-3 row">
              <label for="inputBuilding" class="col-sm-3 col-form-label">メルマガ受信</label>
              <div class="col-sm-6">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" value="" id="mailmagazine" checked>
                  <label for="mailmagazine" class="form-check-label">
                    メルマガを希望する
                  </label>
                </div>
              </div>
            </div> -->
            <div class="btn-area mb-5">
              <button class="btn btn-apply" type="button" @click="handleUpdate()">登録</button>
            </div>
            <child-component v-for="(child, index) in children" :key="child.count" ref="childRef" :child="child"
              :index="index" :deleteChild="deleteChild" />
          </div>
          <div class="btn-area mb-5">
            <button class="btn btn-primary" type="button" @click="addChild()">子ども追加</button>
          </div>
          <div class="btn-area">
            <button class="btn btn-apply" type="button" @click="handleUpdate()">登録</button>
          </div>         
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { DefineComponent, Ref, computed, nextTick, reactive, ref } from 'vue'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useMemberStore } from '@/stores/member'
import { TMember } from '@/types/member'
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import ChildComponent from './child.vue'
import { useLoadingStore } from '@/stores/loading'
import jsonpAdapter from 'axios-jsonp'
import { API } from '../../../api'
import { handleErrors } from '@/config/handleErrors'

const router = useRouter()
const thisYear = new Date().getFullYear()
const memberStore = useMemberStore()
const parent = ref<TMember>(TMember.createEmptyMember())
const children = ref<TMember[]>([])
const childRef = ref<typeof ChildComponent[]>([])
const childErrors = ref<any[]>([])

const childMethod = async () => {
  if (childRef.value) {
    await Promise.all(childRef.value.map(async child => {
      await child.childValidate()
    }))
  }
}

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
})

const schema = yup.object({
  // mail_address: yup.string().required('ユーザーIDは必須項目です').email('メールアドレスの形式で入力してください'),
  // password: yup.string().required('パスワードは必須項目です'),
  lastName: yup.string().required('氏名は必須項目です').max(16, '氏名は16文字以内で入力してください'),
  firstName: yup.string().required('氏名は必須項目です').max(16, '氏名は16文字以内で入力してください'),
  lastNameKana: yup.string().required('氏名(フリガナ)は必須項目です').max(16, '氏名(フリガナ)は16文字以内で入力してください').matches(/^[\u30A1-\u30F6ー]+$/, '全角カタカナのみを入力してください'),
  firstNameKana: yup.string().required('氏名(フリガナ)は必須項目です').max(16, '氏名(フリガナ)は16文字以内で入力してください').matches(/^[\u30A1-\u30F6ー]+$/, '全角カタカナのみを入力してください'),
  year: yup.string().required('年は必須項目です'),
  month: yup.string().required('月は必須項目です'),
  day: yup.string().required('日は必須項目です'),
  gender: yup.number().required('性別は必須項目です'),
  phoneNumber: yup.string().required('電話番号は必須項目です').max(13, '電話番号は13桁以内で入力してください').matches(/^\d{10,13}$/, { message: '全て数字で入力してください' }),
  postalCode: yup.string().required('郵便番号は必須項目です').max(7, '郵便番号は7桁以内で入力してください').matches(/^\d{7}$/, {message: '半角数字7桁で入力してください', excludeEmptyString: true }).nullable(),
  prefecture: yup.string().required('都道府県は必須項目です'),
  city: yup.string().required('市区町村は必須項目です').max(20, '市区町村は20文字以内で入力してください'),
  streetAddress: yup.string().required('番地は必須項目です').max(20, '番地は20文字以内で入力してください'),
  buildingName: yup.string().max(100, '建物名は100文字以内で入力してください').nullable(),
})

// const children = ref<TMember[]>()
const { validate, errors, values, handleSubmit } = useForm({ validationSchema: schema })
const mailAddress = memberStore.$state.mailAddress;
const { value: mailVerifiedAt } = useField<Date>('mailVerifiedAt')
const { value: password } = useField<string>('password')
// const { value: lastName } = useField<string>('lastName')
const { value: firstName } = useField<string>('firstName')
const { value: lastNameKana } = useField<string>('lastNameKana')
const { value: firstNameKana } = useField<string>('firstNameKana')
// const { value: nickname } = useField<string>('nickname')
// const { value: birthday } = useField<string>('birthday')
const { value: year } = useField<string>('year')
const { value: month } = useField<string>('month')
const { value: day } = useField<string>('day')
const { value: gender } = useField<number>('gender')
const { value: phoneNumber } = useField<string>('phoneNumber')
const { value: postalCode } = useField<string>('postalCode')
const { value: prefecture } = useField<string>('prefecture')
const { value: city } = useField<string>('city')
const { value: streetAddress } = useField<string>('streetAddress')
const { value: buildingName } = useField<string>('buildingName')
const { value: attendanceCount } = useField<number>('attendanceCount')
const { value: isMailMagazine } = useField<boolean>('isMailMagazine', undefined, { initialValue: false })
const { value: apiToken } = useField<string>('apiToken')
const { value: loginAttempts } = useField<number>('loginAttempts')
const { value: lockedOutUntil } = useField<Date>('lockedOutUntil')
const { value: lastLogin } = useField<Date>('lastLogin')
const { value: rememberToken } = useField<string>('rememberToken')

useLoadingStore().start()

const count = ref(0)
onMounted(async () => {
  try {
    const {
      parent: fetchParent,
      children: fetchChildren,
    }
      = await TMember.fetchFamilyMember({ id: Number(memberStore.id) })
    parent.value = fetchParent
    children.value = fetchChildren
    children.value.forEach(c => c.count = ++count.value)
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
})

const getLastDay = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate()
}

const lastDay = computed(() => {
  if (year.value && month.value) {
    return getLastDay(Number(year.value), Number(month.value))
  } else {
    return 31
  }
})

const getAddress = async() => {
  await API.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${parent.value.postalCode}`, {adapter: jsonpAdapter}).then(r => {
    if (r.data.status === 200 && r.data.results && r.data.results.length > 0) {
      const result = r.data.results[0]
      parent.value.prefecture = result.address1
      parent.value.city = result.address2
      parent.value.streetAddress = result.address3
    } else {
      parent.value.prefecture = ''
      parent.value.city = ''
      parent.value.streetAddress = ''
    }
  })
}

const addChild = async () => {
  await childMethod()
  const valid = children.value.find(c => {
    return c.valid && c.valid.hasOwnProperty('valid') && c.valid.valid === false
  })
  if (valid) return
  const newChild = TMember.createEmptyMember(++count.value)
  children.value.push(
    newChild
  )
}
const deleteChild = (index: number) => {
  children.value.splice(index, 1)
}

// const handleUpdate = handleSubmit(async (values) => {
const handleUpdate = async() => {
  // useLoadingStore().start()
  try {
    childErrors.value = []
    await childMethod()
    const valid = await validate()
    if (!valid.valid) {
      return
    }
    const childValidations = await Promise.all(children.value.map(async (c) => {
      if (!await c.valid.valid) {
        childErrors.value.push(c.valid.errors)
        return false;
      }
      return true;
    }))
    if (!childValidations.every(validation => validation)) return
    useLoadingStore().start()
    const result = await TMember.updateFamily({ parent: parent.value, children: children.value }).then(
      result => {
        useLoadingStore().stop()
        if (result === 'success') router.push({ name: 'MypageProfileComplete' })
      }
    )
  } catch (e: any) {
    handleErrors(e)
  }
}
</script>
