<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/mypage/index">マイページ</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">パスワード変更</li>
    </ol>
  </nav>
  <div class="container-fluid">
    <div class="row mypage-contents">
      <side-bar />
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4">
        <h1 class="h2">パスワード変更</h1>
        <div class="top--section">
          <p>新しいパスワードを入力してください。</p>
          <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-4 col-form-label">
              パスワード&nbsp;<span class="badge bg-danger">必須</span></label>
            <div class="col-sm-6">
              <Field type="password" name="password" class="form-control" id="inputPassword" />
              <div class="text-danger form-error">{{ errors.password }}</div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="inputPassword" class="col-sm-4 col-form-label">
              パスワード(確認)&nbsp;<span class="badge bg-danger">必須</span>
            </label>
            <div class="col-sm-6">
              <Field type="password" name="passwordConfirm" class="form-control" id="inputPasswordConfirm" />
              <div class="col-sm-10">
                <div class="text-danger form-error">{{ errors.passwordConfirm }}</div>
              </div>
            </div>
          </div>
          <div class="mb-3 mt-4 row">
            <div class="col-sm-4">
            </div>
            <div class="col-sm-6 font-sm">
              ※半角英数字8文字以上で入力してください。
            </div>
          </div>

          <div class="mb-3 mt-4 row">
            <div class="col-sm-4">
            </div>
            <div class="col-sm-2">
              <button class="btn btn-primary w-100 py-2" type="button" @click="changePassword">
                変更</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import SideBar from '@/components/Layouts/SideBar.vue'
import { useMemberStore } from '@/stores/member';
import { useRouter } from 'vue-router'
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { API } from '../../../api'
import { handleErrors } from '@/config/handleErrors'
import { useLoadingStore } from '@/stores/loading'


const router = useRouter()

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

const schema = yup.object({
  password: yup.string().required('パスワードは必須項目です').matches(/^[A-Za-z0-9]+$/, '半角英数字で入力してください').min(8, 'パスワードは8文字以上で入力してください'),
  passwordConfirm: yup.string().required('パスワード(確認)は必須項目です').matches(/^[A-Za-z0-9]+$/, '半角英数字で入力してください').min(8, 'パスワード(確認)は8文字以上で入力してください').test('match', 'パスワードと一致しません', function (value) {
    return value === this.parent.password;
  }),
});

const { validate, errors, values } = useForm({ validationSchema: schema });
const mailAddress = useMemberStore().$state.mailAddress;
const { value: password } = useField<string>('password')
const { value: passwordConfirm } = useField<string>('passwordConfirm')

const changePassword = async () => {
  const valid = await validate()
  if (!valid.valid) return
  try {
    useLoadingStore().start()
    API.post('/api/password/change', { password: password.value, mailAddress: mailAddress }).then(response => {
      if (response.data.status == "success") {
        useLoadingStore().stop()
        router.push('/mypage/password/complete');
      }
      else {
        useLoadingStore().stop()
      }
    })
  } catch (e: any) {
    useLoadingStore().stop()
    handleErrors(e)
  }
}
</script>